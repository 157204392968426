<template>

  <div class="content">
      <div class="input_buttonGroup">
        <div class="inputGroup">
          <el-input
              @blur="handleSeminarInputCheck"
              :class="seminarTips.inputState"
              v-model="seminar.seminarId">
            <template slot="prepend">講習会ID</template>
          </el-input>
        </div>

        <div class="inputGroup inputText">
          <el-input
              @input="handInvestmentFormat"
              @blur="handleInvestmentInputCheck"
              :class="investmentTips.inputState"
              v-model="investmentPotential" >
            <template slot="prepend">投資可能額</template>
          </el-input>
        </div >
        <div class="button">
          <el-upload
              class="upload-demo"
              ref="upload"
              :action="uploadUrl"
              :on-progress="handleProgress"
              :on-success="handleSuccess"
              :on-error="handleError"
              :show-file-list="false">
            <el-button   size="medium" type="success" :icon=uploadIcon round>ファイル選択</el-button>
          </el-upload>
        </div>
        <div class="button">
          <el-button  size="medium" type="success"  @click="submitVerify = true" icon="el-icon-edit" round>登録</el-button>
          <el-dialog
              title="確認"
              :visible.sync="submitVerify"
              width="30%">
            <span>入力内容でデータを上書きしてもよろしいですか？</span>
            <span slot="footer" class="dialog-footer">
              <el-button @click="submitVerify = false">取消</el-button>
              <el-button type="primary" @click="submitDate" >確定</el-button>
            </span>
          </el-dialog>
        </div>
      </div>

    <el-table
        empty-text="入力データがありません"
        :data="productsMsgList"
        :height="tableHeight"
        style="width: 100%;"
        :header-cell-style="{background:'#409EFF',color:'#fcf7f7'}"
    >
      <el-table-column
          prop="seminarId"
          label="講習会ID"
          width="250"
          :cell-style="rowStyle"
          align="center"
          v-if="false"
      >
        <template v-slot="scope">

          <el-input
              size="medium"
              v-model="scope.row.kindId"
              placeholder="" />
        </template>
      </el-table-column>

      <el-table-column
          prop="kindId"
          label="id"
          width="250"
          :cell-style="rowStyle"
          align="center"
          v-if="false"
      >
        <template  v-slot="scope">
          <el-input
              v-model="scope.row.kindId"
              size="medium"
              placeholder="" />
        </template>
      </el-table-column>

      <el-table-column
          prop="kindName"
          label="分類"
          width="250"
          :cell-style="rowStyle"
          align="center"
      >
        <template v-slot="scope" >

          <el-input
              class="input-border-style f12"
              type="textarea"
              v-model="scope.row.kindName"
          >
          </el-input>
        </template>
      </el-table-column>

      <el-table-column label="2003年～2008年"
                       align="center">

        <el-table-column
            prop=""
            label="倍率"
            width="140"

            align="center"
        >
          <template  v-slot="scope">

            <span v-show="!scope.row.effectiveFrom2003To2008"></span>

            <el-input-number
                class="input-border-style"
                v-show="scope.row.effectiveFrom2003To2008"
                placeholder=""
                :controls ="false"
                :precision="2"
                v-model="scope.row.magnificentFrom2003To2008"
                style="width: 100px;"
                controls-position="right"  :min="0" :max="100">

            </el-input-number>

          </template>
        </el-table-column>

        <el-table-column
            prop="province"
            label="有効"
            width="180"
            align="center">
          <template v-slot="scope">
            <el-switch
                v-model="scope.row.effectiveFrom2003To2008"
                active-color="#13ce66"
                inactive-color="#ff4949"
                active-text="有效"
                inactive-text="無効">
            </el-switch>
          </template>
        </el-table-column>
      </el-table-column>
      <el-table-column label="2008年～2018年" align="center">
        <el-table-column
            prop="magnification_2008_2018"
            label="倍率"
            width="140"
            align="center">
          <template  v-slot="scope">
            <span v-show="!scope.row.effectiveFrom2008To2018"></span>

            <el-input-number
                class="input-border-style"
                v-show="scope.row.effectiveFrom2008To2018"
                v-model="scope.row.magnificentFrom2008To2018"
                size="medium"
                :precision="2"
                :controls ="false"
                style="width: 100px;"
                controls-position="right"  :min="0" :max="10"
            />
          </template>
        </el-table-column>
        <el-table-column
            prop=""
            label="有効"
            width="180"
            align="center">
          <template v-slot="scope">
            <el-switch
                v-model="scope.row.effectiveFrom2008To2018"
                active-color="#13ce66"
                inactive-color="#ff4949"
                active-text="有效"
                inactive-text="無効">
            </el-switch>
          </template>
        </el-table-column>
      </el-table-column>

      <el-table-column label="2018年～2023年" align="center">
        <el-table-column
            prop="magnification_2018_2023"
            label="倍率"
            width="140"

            align="center">
          <template  v-slot="scope">
            <span v-show="!scope.row.effectiveFrom2018To2023"></span>

            <el-input-number
                class="input-border-style"
                v-show="scope.row.effectiveFrom2018To2023"
                type="number"
                v-model="scope.row.magnificentFrom2018To2023"
                size="medium"
                :precision="2"
                :controls ="false"
                style="width: 100px;"
                controls-position="right"  :min="0" :max="10"

            />
          </template>
        </el-table-column>
        <el-table-column
            prop="address"
            label="有効"
            width="180"
            align="center">
          <template v-slot="scope">
            <el-switch

                v-model="scope.row.effectiveFrom2018To2023"
                active-color="#13ce66"
                inactive-color="#ff4949"
                active-text="有效"
                inactive-text="無効">
            </el-switch>
          </template>
        </el-table-column>
      </el-table-column>
      <el-table-column
          prop="explain"
          label="説明"
          width="700"
          align="center">
        <template v-slot="scope" >
          <el-input
              class="input-border-style text-area f12"
           type="textarea"
           :rows="2"
           v-model="scope.row.instructions"
          >

         </el-input>
        </template>
      </el-table-column>
    </el-table>
    </div>
</template>

<style scoped >


.input-border-style{

  border:1px solid #409EFF;
  border-radius:6px;
}

.input-border-style-success{

  border:1px solid #409EFF;
  border-radius:6px;

}

.input-border-style-error{

  border:2px solid #fc0326;
  border-radius:6px;
  color: red;

}
.text-area{
  width: 99%;
}
.input_buttonGroup {
  display: flex;
  align-items: center;
  margin-left: 1rem;
  margin-bottom: 15px;
  margin-top: 15px;

  .inputGroup {
    width: 19%;
    display: flex;
    justify-content: center;
    gap: 20px;
  }

  .button {
    width: 19%;
    display: flex;
    justify-content: right;
  }
}

.inputText{
  margin-left: 200px;
}

.content{
  max-height: 100vh;
  height: 100vh;
  overflow-y: hidden;
  overflow-x: auto;
}

</style>

<script>
import axios from 'axios';
export default {


  mounted() {
    document.title = '事前準備';
    this.screenHeight = document.body.clientHeight;
    window.onresize = () => {
      return (() => {
        this.screenHeight = document.body.clientHeight;
      })();
    };

  },
  computed: {
    uploadUrl() {

      return "/fileReader";
    }
  },
  created () {
   this.productsMsgList = ''

    this.getScreenDate()

    this.closeLoadingScreen()


  },

  watch: {
    screenHeight: {
      handler: function (val) {
          this.tableHeight = val - 90;
      },
      immediate: true,
      deep:true
    },
  },

  methods: {
    handleSeminarInputCheck(){

      const regex = /^[a-zA-Z0-9]*$/;

      if (!regex.test(this.seminar.seminarId) || this.seminar.seminarId === null){
        const h = this.$createElement;
        this.seminarTips.inputState="input-border-style-error"
        this.seminarTips.viewFlg=true
        this.$notify.error({
          title: 'エラー',
          message: h('p', { style: 'color: red'}, '英数字を入力してください。'),
          offset: 80,
          position: 'top-left',
          type: 'error'
        });
        return false
      } else if(this.seminar.seminarId ==='') {
        const h = this.$createElement;
        this.seminarTips.inputState="input-border-style-error"
        this.seminarTips.viewFlg=true
        this.$notify.error({
          title: 'エラー',
          message: h('p', { style: 'color: red'}, '必須項目なので、入力してください。'),
          offset: 80,
          position: 'top-left',
          type: 'error'
        });
        return false
      } else if(this.seminar.seminarId.length>15) {
        const h = this.$createElement;
        this.seminarTips.inputState="input-border-style-error"
        this.seminarTips.viewFlg=true
        this.$notify.error({
          title: 'エラー',
          message: h('p', { style: 'color: red'}, '15桁以内の英数字を入力してください。'),
          offset: 80,
          position: 'top-left',
          type: 'error'
        });
        return false
      }else {
        this.seminarTips.inputState="input-border-style-success"
        this.seminarTips.viewFlg=false
        return true
      }

    },

    convertToHalfWidthNumber(str) {
      return str.replace(/[０-９]/g, match => String.fromCharCode(match.charCodeAt(0) - 0xFEE0));
    },

    handInvestmentFormat(){

      this.investmentPotential = this.convertToHalfWidthNumber(this.investmentPotential)

      this.investmentPotential = this.formatNumber(this.investmentPotential)

    },

    handleInvestmentInputCheck(){

      const regex = /^[0-9]*$/;
      const  wkInvestmentAmount = this.investmentPotential.replace(/,/g, '')
      if (!regex.test(wkInvestmentAmount)){
        const h = this.$createElement;
        this.investmentTips.inputState="input-border-style-error"
        this.investmentTips.viewFlg=true
        this.$notify.error({
          title: 'エラー',
          message: h('p', { style: 'color: red'}, '数字を入力してください。'),
          offset: 80,
          position: 'top-left',
          type: 'error'
        });
        return false

      }else if (wkInvestmentAmount ===''){
        const h = this.$createElement;
        this.investmentTips.inputState="input-border-style-error"
        this.investmentTips.viewFlg=true
        this.$notify.error({
          title: 'エラー',
          message: h('p', { style: 'color: red'}, '必須項目なので、入力してください。'),
          offset: 80,
          position: 'top-left',
          type: 'error'
        });
        return false
      }
      else if (wkInvestmentAmount.length>15){
        const h = this.$createElement;
        this.investmentTips.inputState="input-border-style-error"
        this.investmentTips.viewFlg=true
        this.$notify.error({
          title: 'エラー',
          message: h('p', { style: 'color: red'}, '15桁以内の数字を入力してください。'),
          offset: 80,
          position: 'top-left',
          type: 'error'
        });
        return false
      }
      else {
        this.investmentTips.inputState="input-border-style-success"
        this.investmentTips.viewFlg=false
        this.seminar.investAmount=this.parseNumberInput(wkInvestmentAmount)
        this.investmentPotential=this.formatNumber(wkInvestmentAmount)
        return true
      }

    },

    parseNumberInput(value){
      if (value===null || value ===''){
        return Number(0)
      }else {
        return Number(value.replace(/,/g, ''));
      }
    },

    formatNumber(value){
      if(value===null){
        return '0'
      }
      value = value.toString().replace(/,/g, '');
      return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');

    },

    handleProgress(){
      this.uploadIcon='el-icon-loading'
    },

    handleSuccess(response){
      this.seminar=response.seminar;
      this.productsMsgList=response.productsMsgList
      this.investmentPotential=this.formatNumber(this.seminar.investAmount);
      this.uploadIcon='el-icon-upload'
      this.$message({message:"ファイルを正常に読み込めましたので、画面上の情報を確認してください。",type: 'success'});

    },

    handleError(){
      this.$message.error('ファイルの読み込みに失敗しましたので、ファイル形式を確認してください。');
      this.uploadIcon='el-icon-upload'
    },

    getScreenDate(){

      this.openLoadingScreen()
      this.tableLoading=true
      axios.post("/getSeminarDate")
          .then(response => {
            this.seminar=response.data.seminar;
            this.productsMsgList=response.data.productsMsgList;
            this.investmentPotential=this.formatNumber(this.seminar.investAmount);
            this.tableLoading=false
          })
          .catch(error => {
            this.$message.error('画面表示ができませんので、管理者に連絡してください。');
            console.error(error);
          });
    },

    rowStyle() {
      return "text-align:center";
    },

    openLoadingScreen() {
      this.$loading({
        lock: true,
        text: '画面ロード中...',
        spinner: 'el-icon-loading',
        background: 'rgba(255, 255, 255, 255)'
      });
    },

    closeLoadingScreen(options){
      this.$loading(options).close();
    },

    submitDate() {

       const seminarInputCheck = this.handleSeminarInputCheck();

       const InvestmentInputCheck = this.handleInvestmentInputCheck();

      this.submitVerify = false
       if (seminarInputCheck&&InvestmentInputCheck){
         this.seminar.investAmount=this.parseNumberInput(this.investmentPotential)
         const data = {
           seminar: this.seminar,
           productsMsgList:this.productsMsgList
         };

         axios.post("/saveSeminarDate", data)
             .then(response => {
               if (response.data ===true)
                 this.$message({message:"データを正常に登録しました。",type: 'success'});
             })
             .catch(error => {
               this.$message.error('データ登録に失敗しました。');
               console.error(error);
             });

       }else {
         if (seminarInputCheck === false){
           this.$message.error('講習会IDが正しくありません。');
         }

         if (InvestmentInputCheck === false){
           this.$message.error('投資可能額が正しくありません。');
         }

       }

        },


    },

    data() {
      return {
        screenHeight:0,
        tableHeight: 860,
        tableLoading:false,

        productsMsgList: [{
          seminarId:'',
          kindId:'',
          kindName: '',
          magnificentFrom2003To2008: 0,
          effectiveFrom2003To2008: false,
          magnificentFrom2008To2018: 0,
          effectiveFrom2008To2018: false,
          magnificentFrom2018To2023: 0,
          effectiveFrom2018To2023: false,
          instructions: ''
        }],
        seminar:{
          seminarId: '',
          investAmount: 0,
          expPeriodFlag:0
        },

        seminarTips:{
          inputState:'input-border-style-success',
          seminarIdText:'',
          viewFlg:false
        },
        investmentTips:{
          inputState:'input-border-style-success',
          investmentText:'',
          viewFlg:false
        },
        investmentPotential:'',

        uploadIcon:'el-icon-upload',
        submitIcon:'',
        submitVerify:false,

      }
    }

  }


</script>