<template>
  <div class="content">
    <div class="flex align-center justify-content-center title" style="font-weight: bold;font-size: 20px;color: blue">受講者:{{this.stuAssetsMsgList[0].stuNo}}</div>

    <div class="flex align-center justify-content-center flex-end option-group">
      <div class="option-item">
        <div class="label f12"> 投資スタイルを選ぼう！</div>
        <el-select
            disabled
            class="input-border-style"
            v-model="stuAssetsMsgList[0].intendedRate" placeholder="選択してください" >
          <el-option
              class="f14"
              v-for="item in options"
              :key="item.value"
              :label="item.label"
              :value="item.label"
          >
          </el-option>
        </el-select>
      </div>
      <div class="title option-item center f12"  v-show="seminar.expPeriodFlag ===0"> 2003年～2008年：投資してみよう</div>
      <div class="title option-item center f12"  v-show="seminar.expPeriodFlag ===1"> 2008年～2018年：投資してみよう</div>
      <div class="title option-item center f12"  v-show="seminar.expPeriodFlag ===2"> 2018年～2023年：投資してみよう</div>
      <div class="title option-item center f12"  v-show="seminar.expPeriodFlag ===3"> 最終結果</div>
      <div class="option-item center">
        <el-button type="warning" class="f12" @click="handleButtonDateRefresh" icon="el-icon-refresh" round>データ再表示</el-button></div>

      <div class="option-item  flex flex-end align-center">
        <div class="left">
          <div class="label f12"> あなたの年利回りは</div>
          <div class="border-color-red bg-color-theme messageBox" >
            <div class="content-center f12" v-show="seminar.expPeriodFlag===1">
              {{formattedYield2003To2008}}
            </div>

            <div class="content-center f12" v-show="seminar.expPeriodFlag===2">
              {{formattedYield2008To2018}}
            </div>

            <div class="content-center f12" v-show="seminar.expPeriodFlag===3">
              {{formattedYield2018To2023}}
            </div>
          </div>
        </div>
      </div>

      <div class="option-item flex flex-end align-center">
        <div class="left">
          <div class="label f12"> あなたの最終結果</div>
          <div class="border-color-red bg-color-theme messageBox" v-show="seminar.expPeriodFlag===3">
            <div class="content-center f12" >
              {{resultInvestMoney}}
            </div>
          </div>
          <div>
            <div class="border-color-red bg-color-theme messageBox" v-show="seminar.expPeriodFlag!==3">
              <div class="content-center f12" >
              </div>
            </div>
          </div>

        </div>
      </div>

    </div>


    <el-table
        :data="stuInvestmentMsgDisplayList"
        border
        :summary-method="getCashmaries"
        show-summary
        :max-height="tableHeight"
        :header-cell-style="{background:'#409EFF',color:'#fcf7f7'}"
        style="width: 100%"
    >

      <el-table-column
          v-if="false"
          prop="kindId"
          label="分類"
          width="250"
          :cell-style="rowStyle"
          header-align="center"
      >
      </el-table-column>

      <el-table-column
          prop="kindName"
          label="分類"
          width="250"
          :cell-style="rowStyle"
          header-align="center"
      >
        <template v-slot="scope">
          <span v-html="convertNewLines(scope.row.kindName)"></span>
        </template>
      </el-table-column>

      <el-table-column
          width="300"
          header-align="center"
          align="left"
      >
        <template slot="header">

          <div>2003年～2008年</div>
          <div>投資可能額 : {{formatNumber(this.stuAssetsMsgList[0].investFrom2003To2008Start) }}</div>

        </template>

        <template v-slot="scope">

          <span v-show="!scope.row.effectiveFrom2003To2008"></span>

          <div v-show="scope.row.effectiveFrom2003To2008&&seminar.expPeriodFlag>0">投資額:{{scope.row.investmentAmountFrom2003To2008}} </div>
          <div v-show="scope.row.effectiveFrom2003To2008&&seminar.expPeriodFlag>0">投資後:{{scope.row.investmentAmountTotalFrom2003To2008}} </div>

          <div >
            <div class="flex justify-content-center" v-show="scope.row.effectiveFrom2003To2008&& seminar.expPeriodFlag===0">投資額:
              <div class="end-char">

                <div class="input-box-style">
                  {{scope.row.investmentAmountFrom2003To2008}}
                </div>
              </div>
            </div>
          </div>
        </template>

      </el-table-column>
      <el-table-column
          width="300"
          label="2008年～2018年"
          header-align="center"
          align="left">
        <template slot="header">

          <div>2008年～2018年</div>
          <div v-show="seminar.expPeriodFlag>=1">投資可能額 : {{formatNumber(this.stuAssetsMsgList[0].investFrom2008To2018Start) }}</div>

        </template>
        <template v-slot="scope">

          <span v-show="!scope.row.effectiveFrom2008To2018"></span>
          <div v-show="(scope.row.effectiveFrom2008To2018)&&(seminar.expPeriodFlag>1)">投資額:{{scope.row.investmentAmountFrom2008To2018}} </div>
          <div v-show="(scope.row.effectiveFrom2008To2018)&&(seminar.expPeriodFlag>1)">投資後:{{scope.row.investmentAmountTotalFrom2008To2018}} </div>


          <div  class="flex justify-content-center" v-show="scope.row.effectiveFrom2008To2018&&seminar.expPeriodFlag===1">投資額:
            <div class="end-char">

              <div class="input-box-style" v-show="scope.row.effectiveFrom2008To2018&&seminar.expPeriodFlag===1">
                {{scope.row.investmentAmountFrom2008To2018}}
              </div>
            </div>
          </div>
        </template>

      </el-table-column>

      <el-table-column
          width="300"
          label="2018年～2023年"
          header-align="center"
          align="left">
        <template slot="header">

          <div>2018年～2023年</div>
          <div v-show="seminar.expPeriodFlag>=2">投資可能額 : {{formatNumber(this.stuAssetsMsgList[0].investFrom2018To2023Start) }}</div>

        </template>

        <template v-slot="scope">

          <span v-show="(!scope.row.effectiveFrom2018To2023)"></span>
          <div v-show="(scope.row.effectiveFrom2018To2023)&&(seminar.expPeriodFlag>2)">投資額:{{scope.row.investmentAmountFrom2018To2023}} </div>
          <div v-show="(scope.row.effectiveFrom2018To2023)&&(seminar.expPeriodFlag>2)">投資後:{{scope.row.investmentAmountTotalFrom2018To2023}} </div>
          <div class="flex justify-content-center" v-show="scope.row.effectiveFrom2018To2023&&seminar.expPeriodFlag===2">投資額:
            <div class="end-char">

              <div class="input-box-style" v-show="scope.row.effectiveFrom2018To2023&&seminar.expPeriodFlag===2">
                {{scope.row.investmentAmountFrom2018To2023}}
              </div>
            </div>
          </div>
        </template>

      </el-table-column>
      <el-table-column
          prop="instructions"
          label="説明"
          width="760"
          header-align="center">
      </el-table-column>
    </el-table>
<!--    <el-table-->
<!--        :show-header="false"-->
<!--        :data="stuAssetsMsgList"-->
<!--        border-->
<!--        height="50"-->
<!--        :cell-style="tableRowClassEnd"-->
<!--        :header-cell-style="{background:'#5b92cc',color:'#fcf7f7'}"-->
<!--        style="width: 100%"-->
<!--    >-->
<!--      <el-table-column-->
<!--          prop="kind"-->
<!--          label="分類"-->
<!--          :cell-style="rowStyle"-->
<!--          width="250"-->
<!--          align="center"-->
<!--      >-->
<!--        <template>-->
<!--          <span class="bold-text">-->
<!--            現金(投資可能残)-->
<!--          </span>-->
<!--        </template>-->
<!--      </el-table-column>-->

<!--      <el-table-column label="2003年～2008年"-->
<!--                       align="center">-->
<!--        <el-table-column-->
<!--            label="スタート"-->
<!--            width="300"-->
<!--            align="center"-->
<!--        >-->
<!--          <template  v-slot="scope">-->
<!--            <div class="bold-text">{{formatNumber(scope.row.cashFrom2003To2008Start)}}</div>-->
<!--          </template>-->

<!--        </el-table-column>-->


<!--      </el-table-column>-->
<!--      <el-table-column label="2008年～2018年" align="center">-->
<!--        <el-table-column-->
<!--            prop="cashFrom2008To2018Start"-->
<!--            label="スタート"-->
<!--            width="300"-->

<!--            align="center">-->
<!--          <template v-slot="scope">-->
<!--            <span v-show="seminar.expPeriodFlag>=1" class="bold-text">{{formatNumber(scope.row.cashFrom2008To2018Start)}} </span>-->
<!--            <span v-show="seminar.expPeriodFlag<1"></span>-->


<!--          </template>-->


<!--        </el-table-column>-->
<!--      </el-table-column>-->

<!--      <el-table-column label="2018年～2023年" align="center">-->
<!--        <el-table-column-->
<!--            prop="cashFrom2018To2023Start"-->
<!--            label="スタート"-->
<!--            width="300"-->

<!--            align="center">-->
<!--          <template v-slot="scope">-->
<!--            <span v-show="seminar.expPeriodFlag>=2" class="bold-text">{{formatNumber(scope.row.cashFrom2018To2023Start)}} </span>-->

<!--            <span v-show="seminar.expPeriodFlag<2"></span>-->

<!--          </template>-->

<!--        </el-table-column>-->
<!--      </el-table-column>-->
<!--      <el-table-column-->
<!--          prop="instructions"-->
<!--          label="説明"-->
<!--          width="760"-->
<!--          align="center"-->
<!--      >-->
<!--      </el-table-column>-->
<!--    </el-table>-->


  </div>

</template>

<style scoped>

.content{
  max-height: 100vh;
  height: 100vh;
  overflow-y: hidden;
  overflow-x: auto;
}

.el-table .success-row {
  background: #f0f9eb;
}
.title{
  margin-top: 1rem;
}
.option-item{
  width: 20%;
}
.label{
  margin-bottom: 2px;
}
.left{
  .label{
    color: crimson;
  }
}
.option-group{
  margin: 1rem 0.5rem;

}
.end-char{
  margin-left: 1rem;
}

.input-border-style{
  border:1px solid #409EFF;
  border-radius:6px;
  width: 220px;

}
.content-center{
  color: crimson;
}

.input-box-style{
  display: flex;
  align-items: center;
  padding-left: 1rem;
  height: 35px;
  width: 180px;
  border:1px solid #409EFF;
  border-radius:3px;
}
.messageBox{
  display: flex;
  align-items: center;
  padding-left: 1rem;
  height: 35px;
  width: 200px;
  border-radius:3px;

}

.el-input.is-disabled .el-input__inner {
  color: #312f31;
}

.el-table .el-table__cell {
    padding: 10px 0;

}
</style >

<script>
import axios from "axios";
export default {

  mounted() {
    document.title = '受講者:'+this.$route.params.stuNo;

    this.screenHeight = document.body.clientHeight;
    window.onresize = () => {
      return (() => {
        this.screenHeight = document.body.clientHeight;
      })();
    };

  },

  watch: {
    screenHeight: {
      handler: function (val) {
        this.tableHeight = val - 135;
      },
      immediate: true,
      deep:true
    },
  },

  created(){

    this.openLoadingScreen()

    axios.post("/getStudentMsg",
        {
          seminarIdNo: this.$route.params.seminarId,
          studentNo:this.$route.params.stuNo
        },

    ).then(response => {

        this.closeLoadingScreen()
        this.seminar= response.data.seminar;
        this.stuAssetsMsgList= response.data.stuAssetsMsgList;

        this.stuInvestmentMsgList=response.data.stuInvestmentMsgList;

        this.resultInvestMoney=this.formatNumberInput(this.stuAssetsMsgList[0].resultInvest)

        this.formatStuInvestmentMsgToString();


    })
        .catch(error => {

          console.log(error)
        });
  },

  computed: {
    formattedYield2003To2008() {

      if(this.stuAssetsMsgList[0].yieldFrom2003To2008!=null){
        return this.stuAssetsMsgList[0].yieldFrom2003To2008+ '%';
      }else {
        return 0+'%'
      }
    },

    formattedYield2008To2018() {

      if(this.stuAssetsMsgList[0].yieldFrom2008To2018!=null){
        return this.stuAssetsMsgList[0].yieldFrom2008To2018+ '%';
      }else {
        return 0+'%'
      }
    },

    formattedYield2018To2023() {

      if(this.stuAssetsMsgList[0].yieldFrom2018To2023!=null){
        return this.stuAssetsMsgList[0].yieldFrom2018To2023+ '%';
      }else {
        return 0+'%'
      }
    },


  },

  methods: {

    openLoadingScreen() {
      this.$loading({
        lock: true,
        text: 'ページ読み込み中...',
        spinner: 'el-icon-loading',
        background: 'rgba(255, 255, 255, 255)'
      });
    },

    closeLoadingScreen(options){
      this.$loading(options).close();
    },


    handleButtonDateRefresh(){
      axios.post("/getStudentMsg",
          {
            seminarIdNo: this.seminar.seminarId,
            studentNo:this.stuAssetsMsgList[0].stuNo
          },

      ).then(response => {

        this.seminar= response.data.seminar;
        this.stuAssetsMsgList= response.data.stuAssetsMsgList;

        this.stuInvestmentMsgList=response.data.stuInvestmentMsgList;

        this.resultInvestMoney= this.formatNumberInput(this.stuAssetsMsgList[0].resultInvest);

        this.formatStuInvestmentMsgToString();


      })
          .catch(error => {

            console.log(error)
          });
    },

    formatNumberInput(value){

      if(value===null){
        return value
      }else if(typeof value !=='number'){
        value = value.replace(/,/g, '');
      }
      return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');

    },

    formatNumber(value) {
      if (typeof value !== 'number') {
        return value;
      }
      return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    }
    ,

    convertNewLines(text) {
      return text.replace(/\n/g, '<br>');
    },


    formatStuInvestmentMsgToString(){

      this.stuInvestmentMsgDisplayList.splice(0, this.stuInvestmentMsgDisplayList.length);
      this.stuInvestmentMsgList.forEach(item => {
        const newItem = {
          stuNo: item.stuNo,
          kindId: item.kindId,
          kindName: item.kindName,
          effectiveFrom2003To2008:item.effectiveFrom2003To2008,
          effectiveFrom2008To2018:item.effectiveFrom2008To2018,
          effectiveFrom2018To2023:item.effectiveFrom2018To2023,
          investmentAmountFrom2003To2008: this.formatNumberInput(item.investmentAmountFrom2003To2008),
          investmentAmountFrom2008To2018: this.formatNumberInput(item.investmentAmountFrom2008To2018),
          investmentAmountFrom2018To2023: this.formatNumberInput(item.investmentAmountFrom2018To2023),
          investmentAmountTotalFrom2003To2008: this.formatNumberInput(item.investmentAmountTotalFrom2003To2008),
          investmentAmountTotalFrom2008To2018: this.formatNumberInput(item.investmentAmountTotalFrom2008To2018),
          investmentAmountTotalFrom2018To2023: this.formatNumberInput(item.investmentAmountTotalFrom2018To2023),
          instructions:item.instructions

        };
        this.stuInvestmentMsgDisplayList.push(newItem)

      });

    },


    tableRowClassEnd({rowIndex}) {
      if (rowIndex === 0) {
        return "background:rgb(140, 197, 255);"
      }
      return '';
    },

    rowStyle() {
      return "text-align:center";
    },
    getCashmaries(param) {
      const { columns } = param;
      const sums = [];
      columns.forEach((column, index) => {
        if (index === 0) {
          sums[index] = '現金(投資可能残)';
        }

        if (index === 1) {
          sums[index] = this.formatNumber(this.stuAssetsMsgList[0].cashFrom2003To2008Start);
        }

        if (index === 2&&this.seminar.expPeriodFlag>=1) {
          sums[index] = this.formatNumber(this.stuAssetsMsgList[0].cashFrom2008To2018Start);
        }

        if (index === 3&&this.seminar.expPeriodFlag>=2) {
          sums[index] = this.formatNumber(this.stuAssetsMsgList[0].cashFrom2018To2023Start);
        }

      });

      return sums;
    },
    },
  data() {
    return {

      screenHeight:0,
      tableHeight: 860,

      seminar: {
        seminarId: '',
        investAmount: 0,
        expPeriodFlag: 0
      },

      stuAssetsMsgList: [{
        stuId: '',
        stuNo: '',
        seminarId: '',
        intendedRate: '',
        investFrom2003To2008Start: 0,
        fndFrom2003To2008Start: 0,
        cashFrom2003To2008Start: 0,
        investFrom2003To2008End: 0,
        fndFrom2003To2008End: 0,
        cashFrom2003To2008End: 0,
        investFrom2008To2018Start: 0,
        fndFrom2008To2018Start: 0,
        cashFrom2008To2018Start: 0,
        investFrom2008To2018End: 0,
        fndFrom2008To2018End: 0,
        cashFrom2008To2018End: 0,
        investFrom2018To2023Start: 0,
        fndFrom2018To2023Start: 0,
        cashFrom2018To2023Start: 0,
        investFrom2018To2023End: 0,
        fndFrom2018To2023End: 0,
        cashFrom2018To2023End: 0,
        statusFlgFrom2003To2008: 0,
        statusFlgFrom2008To2018: 0,
        statusFlgFrom2018To2023: 0,
        resultInvest: 0,
        resultCash: 0,
        yieldFrom2003To2008: 0,
        yieldFrom2008To2018: 0,
        yieldFrom2018To2023:0
      }],

      stuInvestmentMsgList: [
        {
          stuNo: '',
          kindId: 0,
          kindName: '',
          effectiveFrom2003To2008:false,
          effectiveFrom2008To2018:false,
          effectiveFrom2018To2023:false,
          investmentAmountFrom2003To2008: 0,
          investmentAmountTotalFrom2003To2008: 0,
          investmentAmountFrom2008To2018: 0,
          investmentAmountTotalFrom2008To2018: 0,
          investmentAmountFrom2018To2023: 0,
          investmentAmountTotalFrom2018To2023: 0,
          instructions: ''
        }
      ],

      stuInvestmentMsgDisplayList: [
        {
          stuNo: '',
          kindId: 0,
          kindName: '',
          effectiveFrom2003To2008:false,
          effectiveFrom2008To2018:false,
          effectiveFrom2018To2023:false,
          investmentAmountFrom2003To2008: '',
          investmentAmountTotalFrom2003To2008: '',
          investmentAmountFrom2008To2018: '',
          investmentAmountTotalFrom2008To2018: '',
          investmentAmountFrom2018To2023: '',
          investmentAmountTotalFrom2018To2023: '',
          instructions: ''
        },

      ],

      resultInvestMoney:'',

      options: [{
        value: 'changeOne',
        label: '安定派：年利回り3%以下'
      }, {
        value: 'changeTwo',
        label: '中間派：年利回り3~8%'
      }, {
        value: 'changeThird',
        label: '積極派：年利回り8％以上'
      }
      ],

      space:''
    }

  }
}
</script>