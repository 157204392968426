<template>
  <div class="relative">
    <div class="relative option-item resultMes fb">
        <div class="title center f12 flex"  v-show="seminar.expPeriodFlag ===0"> 2003年～2008年：投資してみよう</div>
        <div class="title center f12 flex"  v-show="seminar.expPeriodFlag ===1"> 2008年～2018年：投資してみよう</div>
        <div class="title center f12 flex"  v-show="seminar.expPeriodFlag ===2"> 2018年～2023年：投資してみよう</div>
        <div class="title center f12 flex"  v-show="seminar.expPeriodFlag ===3"> 最終結果</div>
      </div>
    <div class="flex align-center flex-start option-group ">
      <div class="option-item relative">
        <div class="label f12 absolute"> 投資スタイルを選ぼう！</div>
        <el-select
            class="input-border-style"
            @change="selectChange"
            v-model="select" placeholder="選択してください" >
          <el-option
              class="f12"
              v-for="item in options"
              :key="item.value"
              :label="item.label"
              :value="item.label"
          >
          </el-option>
        </el-select>
      </div>

      <div class="option-item right"><el-button class="f12" type="success" v-show="seminar.expPeriodFlag<3"  @click="handleButtonClick" icon="el-icon-edit" round>投資確定</el-button></div>
<!--      <el-dialog-->
<!--          title="プロンプト"-->
<!--          :visible.sync="buttonSubmit"-->
<!--          width="30%">-->
<!--        <span>投資してよろしいですか？</span>-->
<!--        <span slot="footer" class="dialog-footer">-->
<!--          <el-button @click="buttonSubmit = false">取り消し</el-button>-->
<!--          <el-button type="primary" @click="handleButtonClick">確定</el-button>-->
<!--        </span>-->
<!--      </el-dialog>-->


      <div class="option-item center"><el-button class="f12" type="success" v-show="seminar.expPeriodFlag<3"  @click="handleButtonClickContinue"  icon="el-icon-right" round>
        
        <span v-show="seminar.expPeriodFlag<2">次の年代へ</span>
        <span v-show="seminar.expPeriodFlag===2">最終結果表示</span>
      </el-button>
      </div>

      <div class="option-item  flex flex-end align-center">
        <div class="left relative">
          <div class="label-left f12 absolute content-center"> あなたの年利回り</div>
          <div class="border-color-red bg-color-theme messageBox" >
            <div class="content-center f12" v-show="seminar.expPeriodFlag===1">
              {{formattedYield2003To2008}}
            </div>

            <div class="content-center f12" v-show="seminar.expPeriodFlag===2">
              {{formattedYield2008To2018}}
            </div>

            <div class="content-center f12" v-show="seminar.expPeriodFlag===3">
              {{formattedYield2018To2023}}
            </div>
          </div>
        </div>
      </div>

      <div class="option-item flex flex-end align-center">
        <div class="left relative">
          <div class="label-left f12 absolute content-center"> あなたの最終結果</div>
          <div class="border-color-red bg-color-theme messageBox" v-show="seminar.expPeriodFlag===3">
            <div class="content-center f12" >
              {{resultInvestMoney}}
            </div>
          </div>
          <div>
            <div class="border-color-red bg-color-theme messageBox" v-show="seminar.expPeriodFlag!==3">
              <div class="content-center" >
              </div>
            </div>
          </div>
        </div>
        </div>

    </div>

      <el-table
          empty-text="データがありません"
          :data="stuInvestmentMsgDisplayList"
          border
          :summary-method="getCashmaries"
          show-summary
          max-height="585"
          :header-cell-style="{background:'#409EFF',color:'#fcf7f7'}"
          style="width: 1190px"
      >

        <el-table-column
            v-if="false"
            prop="kindId"
            label="分類"
            width="60"
            :cell-style="rowStyle"
            header-align="top"
        >
        </el-table-column>

        <el-table-column
            label-class-name="cell-top"
            prop="kindName"
            label="分類"
            width="200"
            :cell-style="rowStyle"
            header-align="center"
            test-align="center"
        >
          <template v-slot="scope">
            <span v-html="convertNewLines(scope.row.kindName)"></span>
          </template>
        </el-table-column>

        <el-table-column
            label-class-name="cell-top"
            width="200"
            header-align="center"
            align="left">
          <template slot="header">

            <div>2003年～2008年</div>
            <div>投資可能額 : {{formatNumber(this.stuAssetsMsgList[0].investFrom2003To2008Start) }}</div>

          </template>

          <template v-slot="scope">

            <span v-show="!scope.row.effectiveFrom2003To2008"></span>

            <div v-show="scope.row.effectiveFrom2003To2008&&seminar.expPeriodFlag>0">投資額:{{scope.row.investmentAmountFrom2003To2008}} </div>
            <div v-show="scope.row.effectiveFrom2003To2008&&seminar.expPeriodFlag>0">投資後:{{scope.row.investmentAmountTotalFrom2003To2008}} </div>

            <div >
            <div class="flex justify-content-center" v-show="scope.row.effectiveFrom2003To2008&& seminar.expPeriodFlag===0">投資額:
              <div class="end-char">
              <el-input
                  :class="scope.row.inputDateStatusFirst"
                  class="input-border-style-success"
                  :controls ="false"
                  style="width: 120px;"
                  :debounce="10000"
                  @input="handleInput2003To2008Check(scope.row)"
                  @blur="handleInput2003To2008Submit(scope.row)"
                  v-model="scope.row.investmentAmountFrom2003To2008"
                  size="medium"/>
              </div>
            </div>
              <div class="f11 font-color fb" v-show="scope.row.subErrHanDonFirst">{{scope.row.subErrMessageFirst}}</div>
            </div>
          </template>

        </el-table-column>
        <el-table-column
            label-class-name="cell-top"
            width="200"
            label="2008年～2018年"
            header-align="center"
            align="left">
          <template slot="header">

            <div>2008年～2018年</div>
            <div v-show="seminar.expPeriodFlag>=1">投資可能額 : {{formatNumber(this.stuAssetsMsgList[0].investFrom2008To2018Start) }}</div>

          </template>
          <template v-slot="scope">

            <span v-show="!scope.row.effectiveFrom2008To2018"></span>
            <div v-show="(scope.row.effectiveFrom2008To2018)&&(seminar.expPeriodFlag>1)">投資額:{{scope.row.investmentAmountFrom2008To2018}} </div>
            <div v-show="(scope.row.effectiveFrom2008To2018)&&(seminar.expPeriodFlag>1)">投資後:{{scope.row.investmentAmountTotalFrom2008To2018}} </div>


            <div  class="flex justify-content-center" v-show="scope.row.effectiveFrom2008To2018&&seminar.expPeriodFlag===1">投資額:
              <div class="end-char">
                <el-input
                :class="scope.row.inputDateStatusSecond"
                class="input-border-style-success"
                :controls ="false"
                style="width: 120px;"
                @input="handleInput2008To2018Check(scope.row)"
                @blur="handleInput2008To2018(scope.row)"
                :min="0" :max="999999999999999"
                v-show="scope.row.effectiveFrom2008To2018&&seminar.expPeriodFlag===1"
                v-model="scope.row.investmentAmountFrom2008To2018"
                size="medium"
               />
              </div>
            </div>
            <div class="f11 font-color fb" v-show="scope.row.subErrHanDonSecond">{{scope.row.subErrMessageSecond}}</div>
          </template>

        </el-table-column>

        <el-table-column
            label-class-name="cell-top"
            width="200"
            label="2018年～2023年"
            header-align="center"
            align="left">
          <template slot="header">

            <div>2018年～2023年</div>
            <div v-show="seminar.expPeriodFlag>=2">投資可能額 : {{formatNumber(this.stuAssetsMsgList[0].investFrom2018To2023Start) }}</div>

          </template>

          <template v-slot="scope">

            <span v-show="(!scope.row.effectiveFrom2018To2023)"></span>
            <div v-show="(scope.row.effectiveFrom2018To2023)&&(seminar.expPeriodFlag>2)">投資額:{{scope.row.investmentAmountFrom2018To2023}} </div>
            <div v-show="(scope.row.effectiveFrom2018To2023)&&(seminar.expPeriodFlag>2)">投資後:{{scope.row.investmentAmountTotalFrom2018To2023}} </div>
            <div class="flex justify-content-center" v-show="scope.row.effectiveFrom2018To2023&&seminar.expPeriodFlag===2">投資額:
              <div class="end-char">
                <el-input
                :class="scope.row.inputDateStatusThird"
                 class="input-border-style"
                :controls ="false"
                 @input="handleInput2018To2023Check(scope.row)"
                 @blur="handleInput2018To2023(scope.row)"
                style="width: 120px;"
                :min="0" :max="999999999999999"
                v-show="scope.row.effectiveFrom2018To2023&&seminar.expPeriodFlag===2"
                v-model="scope.row.investmentAmountFrom2018To2023"
                size="medium"
                />
              </div>
            </div>
            <div class="f11 font-color fb" v-show="scope.row.subErrHanDonThird">{{scope.row.subErrMessageThird}}</div>
          </template>

        </el-table-column>
        <el-table-column
            class-name="cell-top"
            prop="instructions"
            label="説明"
            width="385"
            header-align="center"
        >
          <template v-slot="scope">
            <span class="f10"> {{scope.row.instructions}}</span>

          </template>

        </el-table-column>
      </el-table>

    </div>

</template>

<style scoped>
  .resultMes{
    @media screen and (min-width: 768px) {
      margin: 1rem auto 0 500px;
      /* .title{
        width:1185px;
        justify-content: center;
        text-align: center
      } */

    }
    @media screen and (width: 768px) {
      margin: 1rem auto 0 auto;
      /* .title{
        width:1185px;
        justify-content: center;
        text-align: center
      } */

    }
}

.el-table .success-row {
  background: #f0f9eb;
}

.option-item{
  width: 237px;
}
.left{
 .label{
 color: crimson;
}
}
.option-group{
  margin: 1rem 0 .5rem 0;

}
.end-char{
  margin-left: 0.3rem;
}

.input-border-style{
  border:1px solid #409EFF;
  border-radius:6px;
  width: 220px;
  margin-left: 5px;

}
.el-table__row td:last-child div.cell{
  font-size: 2rem !important;
}

.messageBox{
  display: flex;
  align-items: center;
  padding-left: 1rem;
  height: 35px;
  width: 90px;
  border-radius:3px;

}
.messageBox{
  display: flex;
  align-items: center;
  padding-left: 1rem;
  height: 35px;
  width: 90px;
  border-radius:3px;

}

.content-center{
  color: crimson;
}

.option-item{
  .label{
    top: -20px;
    left: 8PX;
    z-index: 1;
  }
  .label-left{
    top: -20px;
    z-index: 1;
  }

}

  .option-item-label-style{
    .label-text {
      top: -20px;
      z-index: 1;
    }
  }

  .input-border-style-success{

    border:1px solid #409EFF;
    border-radius:6px;

  }

  .input-border-style-error{

    border:2px solid red;
    border-radius:6px;

  }

</style >

<script>
import axios from "axios";
export default {
  mounted() {
    document.title = '投資体験';
  },

  created(){

    const regex = /^\d{3}$/;
    const param= this.$route.params.stuNo;

    if(regex.test(param)&&param !=="000"){

      this.openLoadingScreen()
      axios.post("/experienceDateGet",
          {
            seminarIdNo: this.$route.params.seminarId,
            studentNo:this.$route.params.stuNo
          }
      ).then(response => {

        if(response.data === ''){
          this.closeLoadingScreen()
          this.$message({
            showClose: true,
            message: 'URLが正しくありません。' ,
            type: 'error'
          });
        }else {
          this.closeLoadingScreen()
          this.seminar= response.data.seminar;
          this.stuAssetsMsgList= response.data.stuAssetsMsgList;
          this.stuInvestmentMsgList = response.data.stuInvestmentMsgList;

          this.select=this.stuAssetsMsgList[0].intendedRate

          this.resultInvestMoney=this.formatNumberInput(this.stuAssetsMsgList[0].resultInvest)

          this.formatStuInvestmentMsgToString();

        }
      })
          .catch(error => {

            console.log(error)
          });
    }else {
      this.$message({
        showClose: true,
        message: 'URLが正しくありません。' ,
        type: 'error'
      });
    }


  },

  computed: {
    formattedYield2003To2008() {

      if(this.stuAssetsMsgList[0].yieldFrom2003To2008!=null){
        return this.stuAssetsMsgList[0].yieldFrom2003To2008+ '%';
      }else {
        return 0+'%'
      }
    },
    formattedYield2008To2018() {

      if(this.stuAssetsMsgList[0].yieldFrom2008To2018!=null){
        return this.stuAssetsMsgList[0].yieldFrom2008To2018+ '%';
      }else {
        return 0+'%'
      }
    },

    formattedYield2018To2023() {

      if(this.stuAssetsMsgList[0].yieldFrom2018To2023!=null){
        return this.stuAssetsMsgList[0].yieldFrom2018To2023+ '%';
      }else {
        return 0+'%'
      }
    },

  },

  methods: {

    openLoadingScreen() {
      this.$loading({
        lock: true,
        text: '画面ロード中...',
        spinner: 'el-icon-loading',
        background: 'rgba(255, 255, 255, 255)'
      });
    },

    closeLoadingScreen(options){
      this.$loading(options).close();
    },


    formatNumberInput(value){
      if(value===null){
        return '0'
      }
      value = value.toString().replace(/,/g, '');
      return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');

    },

    parseNumberInput(value){

      if (value===null || value ===""){
        return Number(0)
      }else {
        return Number(value.replace(/,/g, ''));
      }
    },

    convertToHalfWidthNumber(str) {

      return str.replace(/[０-９]/g, match => String.fromCharCode(match.charCodeAt(0) - 0xFEE0));

    },

    handleInput2003To2008Check(value){

      value.investmentAmountFrom2003To2008 = value.investmentAmountFrom2003To2008.replace(/,/g, '')

      value.investmentAmountFrom2003To2008 = this.convertToHalfWidthNumber(value.investmentAmountFrom2003To2008)

      this.formatStuInvestmentMsgToNumber();


      const regex = /^[0-9]*$/;

      const  wkInvestmentAmountFrom = value.investmentAmountFrom2003To2008



      if (!regex.test(wkInvestmentAmountFrom)){

        value.inputDateStatusFirst='input-border-style-error';

        value.subErrMessageFirst = '数字を入力してくださいね。'

        value.subErrHanDonFirst =true

        this.submitStatus.submitFirst =false


      }
      else if (Number(wkInvestmentAmountFrom) < 0){

        value.inputDateStatusFirst='input-border-style-error';

        value.subErrHanDonFirst =true

        value.subErrMessageFirst = '数字を入力してくださいね。'

        this.submitStatus.submitFirst =false

      }else if (wkInvestmentAmountFrom>this.stuAssetsMsgList[0].investFrom2003To2008Start){
        value.inputDateStatusFirst='input-border-style-error';

        value.subErrHanDonFirst = true
        value.subErrMessageFirst= '現金もう足りませんよ。'

        this.submitStatus.submitFirst =false
        this.submitStatus.subErrMessageFirst='現金もう足りませんよ。'

      }

      else {

        let totalSum = this.stuInvestmentMsgList.reduce((accumulator, currentValue) => {

          const amount = typeof currentValue.investmentAmountFrom2003To2008 === 'number' && !isNaN(currentValue.investmentAmountFrom2003To2008)? currentValue.investmentAmountFrom2003To2008 : 0;

          return accumulator + amount;
        }, 0);

        let subtraction =this.stuAssetsMsgList[0].investFrom2003To2008Start-totalSum;

        if (subtraction<0){

          value.inputDateStatusFirst='input-border-style-error';
          this.submitStatus.submitFirst =false

          value.subErrHanDonFirst = true
          value.subErrMessageFirst= '現金もう足りませんよ。'

          this.submitStatus.subErrMessageFirst='現金もう足りませんよ。'

        }else{

          this.stuAssetsMsgList[0].cashFrom2003To2008Start=subtraction

          value.inputDateStatusFirst='input-border-style-success';

          value.subErrHanDonFirst =false

          this.submitStatus.submitFirst =true
        }
      }

      if (wkInvestmentAmountFrom ===''){

        value.inputDateStatusFirst='input-border-style-success';
        this.submitStatus.submitFirst =true
        value.subErrHanDonFirst =false
      }

    },

    handleInput2003To2008Submit(value){


      value.investmentAmountFrom2003To2008=this.formatNumberInput(value.investmentAmountFrom2003To2008)

      const regex = /^[0-9]*$/;

      for (let i = 0; i < this.stuInvestmentMsgList.length; i++) {

        if (!regex.test(this.stuInvestmentMsgList[i].investmentAmountFrom2003To2008)){

          this.submitStatus.submitFirst=false

          break;
        }
      }

      if (this.submitStatus.submitFirst === true){

        axios.post("/experienceFirstComputeFirst",
            {
              seminar:this.seminar,
              stuAssetsMsgList: this.stuAssetsMsgList,
              stuInvestmentMsgList:this.stuInvestmentMsgList,
            }).then(response => {

          if(response.data.expPeriodFlag !== this.seminar.expPeriodFlag){

            this.$message({
              showClose: true,
              message: '2003年～2008年の投資が既に終了しましたので、次の年代へ進んでくださいね。' ,
              type: 'error'
            });
          }
        }).catch(error => {

              console.log(error)
            });

      }
    },

    handleInput2008To2018Check(value){

      value.investmentAmountFrom2008To2018 = value.investmentAmountFrom2008To2018.replace(/,/g, '')

      value.investmentAmountFrom2008To2018 = this.convertToHalfWidthNumber(value.investmentAmountFrom2008To2018)

      const regex = /^[0-9]*$/;
      const  wkInvestmentAmountFrom = value.investmentAmountFrom2008To2018

      if (!regex.test(wkInvestmentAmountFrom)){

        value.inputDateStatusSecond='input-border-style-error';

        value.subErrHanDonSecond = true

        value.subErrMessageSecond= '数字を入力してくださいね。'

        this.submitStatus.submitSecond =false

        this.submitStatus.subErrMessageSecond='数字を入力してくださいね。'

      } else if (Number(wkInvestmentAmountFrom) < 0){

        value.inputDateStatusSecond='input-border-style-error';

        value.subErrHanDonSecond = true

        value.subErrMessageSecond= '数字を入力してくださいね。'

        this.submitStatus.submitSecond =false
        this.submitStatus.subErrMessageSecond='数字を入力してくださいね。'

      } else if (wkInvestmentAmountFrom>this.stuAssetsMsgList[0].investFrom2008To2018Start){
        value.inputDateStatusSecond='input-border-style-error';

        value.subErrHanDonSecond = true
        value.subErrMessageSecond= '現金もう足りませんよ。'

        this.submitStatus.submitSecond =false
        this.submitStatus.subErrMessageSecond='現金もう足りませんよ。'

      } else {

        this.formatStuInvestmentMsgToNumber();

        let totalSum = this.stuInvestmentMsgList.reduce((accumulator, currentValue) => {

          const amount = typeof currentValue.investmentAmountFrom2008To2018 === 'number' && !isNaN(currentValue.investmentAmountFrom2003To2008) ? currentValue.investmentAmountFrom2008To2018 : 0;
          return accumulator + amount;
        }, 0);

        let subtraction =this.stuAssetsMsgList[0].investFrom2008To2018Start-totalSum;

        if (subtraction<0){

          value.inputDateStatusSecond='input-border-style-error';

          value.subErrHanDonSecond = true

          value.subErrMessageSecond= '現金もう足りませんよ。'

          this.submitStatus.submitSecond =false
          this.submitStatus.subErrMessageSecond='現金もう足りませんよ。'

        }else {

          this.stuAssetsMsgList[0].cashFrom2008To2018Start=subtraction

          value.inputDateStatusSecond='input-border-style-success';

          value.subErrHanDonSecond = false

          this.submitStatus.submitSecond =true
        }
      }

      if (wkInvestmentAmountFrom ===''){

        value.inputDateStatusSecond='input-border-style-success';

        this.submitStatus.submitSecond =true

        value.subErrHanDonSecond = false
      }

    },

    handleInput2008To2018(value){
      value.investmentAmountFrom2008To2018= this.formatNumberInput(value.investmentAmountFrom2008To2018)

      const regex = /^[0-9]*$/;

      for (let i = 0; i < this.stuInvestmentMsgList.length; i++) {

        if (!regex.test(this.stuInvestmentMsgList[i].investmentAmountFrom2008To2018)){

          this.submitStatus.submitSecond=false

          break;
        }
      }


      if (this.submitStatus.submitSecond === true){
        axios.post("/experienceComputeSecond",
            {
              seminar:this.seminar,
              stuAssetsMsgList: this.stuAssetsMsgList,
              stuInvestmentMsgList:this.stuInvestmentMsgList,
            }).then(response => {

          if(response.data.expPeriodFlag !== this.seminar.expPeriodFlag){
            this.$message({
              showClose: true,
              message: '2008年～2018年の投資が既に終了しましたので、次の年代へ進んでくださいね。' ,
              type: 'error'
            });
          }
        }).catch(error => {

          console.log(error)
        });

      }
    },

    handleInput2018To2023Check(value){

      value.investmentAmountFrom2018To2023=value.investmentAmountFrom2018To2023.replace(/,/g, '')

      value.investmentAmountFrom2018To2023=this.convertToHalfWidthNumber(value.investmentAmountFrom2018To2023)

      const regex = /^[0-9]*$/;
      const  wkInvestmentAmountFrom = value.investmentAmountFrom2018To2023

      if (!regex.test(wkInvestmentAmountFrom)){

        value.inputDateStatusThird='input-border-style-error';

        value.subErrHanDonThird = true

        value.subErrMessageThird= '数字を入力してくださいね。'

        this.submitStatus.subErrMessageThird='数字を入力してくださいね。'
        this.submitStatus.submitThird =false

      } else if (Number(wkInvestmentAmountFrom) < 0){

        value.inputDateStatusThird='input-border-style-error';

        value.subErrHanDonThird = true

        value.subErrMessageThird= '数字を入力してくださいね。'

        this.submitStatus.subErrMessageThird='数字を入力してくださいね。'
        this.submitStatus.submitThird =false
      } else if (wkInvestmentAmountFrom>this.stuAssetsMsgList[0].investFrom2018To2023Start){
        value.inputDateStatusThird='input-border-style-error';

        value.subErrHanDonThird = true
        value.subErrMessageThird= '現金もう足りませんよ。'

        this.submitStatus.submitThird =false
        this.submitStatus.subErrMessageThird='現金もう足りませんよ。'

      } else {

        this.formatStuInvestmentMsgToNumber();

        let totalSum = this.stuInvestmentMsgList.reduce((accumulator, currentValue) => {

          const amount = typeof currentValue.investmentAmountFrom2018To2023 === 'number' && !isNaN(currentValue.investmentAmountFrom2003To2008) ? currentValue.investmentAmountFrom2018To2023 : 0;
          return accumulator + amount;
        }, 0);

        let subtraction =this.stuAssetsMsgList[0].investFrom2018To2023Start-totalSum;

        if (subtraction<0){

          value.inputDateStatusThird='input-border-style-error';

          value.subErrHanDonThird = true

          value.subErrMessageThird= '現金もう足りませんよ。'

          this.submitStatus.subErrMessageThird='現金もう足りませんよ。'
          this.submitStatus.submitThird =false

        }else {

          this.stuAssetsMsgList[0].cashFrom2018To2023Start=subtraction
          value.inputDateStatusThird='input-border-style-success';

          value.subErrHanDonThird = false

          this.submitStatus.submitThird =true
        }
      }

      if (wkInvestmentAmountFrom ===''){
        value.inputDateStatusThird='input-border-style-success';

        value.subErrHanDonThird = false

        this.submitStatus.submitThird =false


      }
    },

    handleInput2018To2023(value){

      value.investmentAmountFrom2018To2023= this.formatNumberInput(value.investmentAmountFrom2018To2023)

      const regex = /^[0-9]*$/;

      for (let i = 0; i < this.stuInvestmentMsgList.length; i++) {

        if (!regex.test(this.stuInvestmentMsgList[i].investmentAmountFrom2018To2023)){

          this.submitStatus.submitSecond=false

          break;
        }
      }


      if (this.submitStatus.submitThird === true){
        axios.post("/experienceComputeThird",
            {
              seminar:this.seminar,
              stuAssetsMsgList: this.stuAssetsMsgList,
              stuInvestmentMsgList:this.stuInvestmentMsgList,
            }).then(response => {

          if(response.data.expPeriodFlag !== this.seminar.expPeriodFlag){
            this.$message({
              showClose: true,
              message: '全ての投資が既に終了しましたので、最終結果を見ましょう！' ,
              type: 'error'
            });
          }
        }).catch(error => {

          console.log(error)
        });
      }
    },

    selectChange(){

      this.stuAssetsMsgList[0].intendedRate=this.select

        if (this.seminar.seminarId === '' || this.stuAssetsMsgList[0].stuNo===''){
          this.$message({
            showClose: true,
            message: 'URLが正しくありません。' ,
            type: 'error'
          });
        }else {
          axios.post("/experienceSelectChange",
              {
                stuAssetsMsgList: this.stuAssetsMsgList,
              },
          )
        }


    },

    formatNumber(value) {
      if (typeof value !== 'number') {
        return value;
      }
      return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    },

    buttonSubmitMethod(){
      axios.post("/confirmInvestment",
          {
            stuAssetsMsgList: this.stuAssetsMsgList,
            stuInvestmentMsgList:this.stuInvestmentMsgList,
            seminar:this.seminar
          }).then(response => {

        if(response.data.expPeriodFlag !== this.seminar.expPeriodFlag){
          if (response.data.expPeriodFlag === 1){
            this.$message({
              showClose: true,
              message: '2003年～2008年の投資が既に終了しましたので、次の年代へ進んでくださいね。' ,
              type: 'error'
            });
          }else if (response.data.expPeriodFlag === 2){
            this.$message({
              showClose: true,
              message: '2008年～2018年の投資が既に終了しましたので、次の年代へ進んでくださいね。' ,
              type: 'error'
            });
          }else if (response.data.expPeriodFlag === 3){
            this.$message({
              showClose: true,
              message: '全ての投資が既に終了しましたので、最終結果を見ましょう！' ,
              type: 'error'
            });
          }
        }else {
          this.$message({
            message: '投資を正常に確定しました。',
            type: 'success'
          });
        }
      }).catch(error => {
        console.log(error)
      });
    },

    handleButtonClick(){

      if (this.seminar.seminarId === ''){
        this.$message({
          showClose: true,
          message: 'URLが正しくありません。' ,
          type: 'error'
        });
      }else {
        this.buttonSubmit=false

        if (this.submitStatus.submitFirst === true){

          this.buttonSubmitMethod()


        }else if (this.submitStatus.submitSecond === true){

          this.buttonSubmitMethod()

        }else if (this.submitStatus.submitThird=== true){

          this.buttonSubmitMethod()

        }else {
          this.$message.error('投資を確定できません、投資額を確認してくださいね。');

        }
      }

    },

    convertNewLines(text) {
      return text.replace(/\n/g, '<br>');
    },

    handleButtonClickContinue(){
      if (this.seminar.seminarId === ''){
        this.$message({
          showClose: true,
          message: 'URLが正しくありません。' ,
          type: 'error'
        });
      }else {
        axios.post("/experienceContinue",
            {
              seminarIdNo: this.seminar.seminarId,
              studentNo: this.stuAssetsMsgList[0].stuNo,
            },
        ).then(response => {
          this.seminar= response.data.seminar;
          this.stuAssetsMsgList= response.data.stuAssetsMsgList;
          this.stuInvestmentMsgList=response.data.stuInvestmentMsgList;
          this.select=this.stuAssetsMsgList[0].intendedRate
          this.resultInvestMoney=this.formatNumberInput(this.stuAssetsMsgList[0].resultInvest)
          this.formatStuInvestmentMsgToString(response.data.stuInvestmentMsgList);

        })
            .catch(error => {

              console.log(error)
            });
      }
    },

    formatStuInvestmentMsgToNumber(){
      this.stuInvestmentMsgList.splice(0, this.stuInvestmentMsgList.length);


      this.stuInvestmentMsgDisplayList.forEach(item => {

        const newItem = {
          stuNo: item.stuNo,
          kindId: item.kindId,
          kindName: item.kindName,
          effectiveFrom2003To2008:item.effectiveFrom2003To2008,
          effectiveFrom2008To2018:item.effectiveFrom2008To2018,
          effectiveFrom2018To2023:item.effectiveFrom2018To2023,
          investmentAmountFrom2003To2008: this.parseNumberInput(item.investmentAmountFrom2003To2008),
          investmentAmountFrom2008To2018: this.parseNumberInput(item.investmentAmountFrom2008To2018),
          investmentAmountFrom2018To2023: this.parseNumberInput(item.investmentAmountFrom2018To2023),
          investmentAmountTotalFrom2003To2008: this.parseNumberInput(item.investmentAmountTotalFrom2003To2008),
          investmentAmountTotalFrom2008To2018: this.parseNumberInput(item.investmentAmountTotalFrom2008To2018),
          investmentAmountTotalFrom2018To2023: this.parseNumberInput(item.investmentAmountTotalFrom2018To2023),
          instructions:item.instructions
        };

        this.stuInvestmentMsgList.push(newItem)

      });
    },

    formatStuInvestmentMsgToString(){

      this.stuInvestmentMsgDisplayList.splice(0, this.stuInvestmentMsgDisplayList.length);
      this.stuInvestmentMsgList.forEach(item => {
        const newItem = {
          stuNo: item.stuNo,
          kindId: item.kindId,
          kindName: item.kindName,
          effectiveFrom2003To2008:item.effectiveFrom2003To2008,
          effectiveFrom2008To2018:item.effectiveFrom2008To2018,
          effectiveFrom2018To2023:item.effectiveFrom2018To2023,
          investmentAmountFrom2003To2008: this.formatNumberInput(item.investmentAmountFrom2003To2008),
          investmentAmountFrom2008To2018: this.formatNumberInput(item.investmentAmountFrom2008To2018),
          investmentAmountFrom2018To2023: this.formatNumberInput(item.investmentAmountFrom2018To2023),
          investmentAmountTotalFrom2003To2008: this.formatNumberInput(item.investmentAmountTotalFrom2003To2008),
          investmentAmountTotalFrom2008To2018: this.formatNumberInput(item.investmentAmountTotalFrom2008To2018),
          investmentAmountTotalFrom2018To2023: this.formatNumberInput(item.investmentAmountTotalFrom2018To2023),
          instructions:item.instructions

        };
        this.stuInvestmentMsgDisplayList.push(newItem)

      });

    },

    rowStyle() {
      return "text-align:center";
    },

    getCashmaries(param) {
      const { columns } = param;
      const sums = [];
      columns.forEach((column, index) => {
        if (index === 0) {
          sums[index] = '現金(投資可能残)';
        }

        if (index === 1) {
          sums[index] = this.formatNumber(this.stuAssetsMsgList[0].cashFrom2003To2008Start);
        }

        if (index === 2&&this.seminar.expPeriodFlag>=1) {
          sums[index] = this.formatNumber(this.stuAssetsMsgList[0].cashFrom2008To2018Start);
        }

        if (index === 3&&this.seminar.expPeriodFlag>=2) {
          sums[index] = this.formatNumber(this.stuAssetsMsgList[0].cashFrom2018To2023Start);
        }

      });

      return sums;
    },


  },
  data() {
    return {
      isLoading: false,
      tableLoading:false,
      seminar: {
        seminarId: '',
        investAmount: 0,
        expPeriodFlag: 0
      },

      stuAssetsMsgList: [{
        stuId: '',
        stuNo: '',
        seminarId: '',
        intendedRate: '',
        investFrom2003To2008Start: 0,
        fndFrom2003To2008Start: 0,
        cashFrom2003To2008Start: 0,
        investFrom2003To2008End: 0,
        fndFrom2003To2008End: 0,
        cashFrom2003To2008End: 0,
        investFrom2008To2018Start: 0,
        fndFrom2008To2018Start: 0,
        cashFrom2008To2018Start: 0,
        investFrom2008To2018End: 0,
        fndFrom2008To2018End: 0,
        cashFrom2008To2018End: 0,
        investFrom2018To2023Start: 0,
        fndFrom2018To2023Start: 0,
        cashFrom2018To2023Start: 0,
        investFrom2018To2023End: 0,
        fndFrom2018To2023End: 0,
        cashFrom2018To2023End: 0,
        statusFlgFrom2003To2008: 0,
        statusFlgFrom2008To2018: 0,
        statusFlgFrom2018To2023: 0,
        resultInvest: 0,
        resultCash: 0,
        yieldFrom2003To2008: 0,
        yieldFrom2008To2018:0,
        yieldFrom2018To2023:0
      }],

      stuInvestmentMsgList: [
          {
        stuNo: '',
        kindId: 0,
        kindName: '',
        effectiveFrom2003To2008:false,
        effectiveFrom2008To2018:false,
        effectiveFrom2018To2023:false,
        investmentAmountFrom2003To2008: 0,
        investmentAmountTotalFrom2003To2008: 0,
        investmentAmountFrom2008To2018: 0,
        investmentAmountTotalFrom2008To2018: 0,
        investmentAmountFrom2018To2023: 0,
        investmentAmountTotalFrom2018To2023: 0,
        instructions: ''
      }
      ],

      stuInvestmentMsgDisplayList: [{
        stuNo: '',
        kindId: 0,
        kindName: '',
        effectiveFrom2003To2008:false,
        inputDateStatusFirst:'input-border-style-success',
        subErrMessageFirst:'',
        subErrHanDonFirst:false,
        effectiveFrom2008To2018:false,
        inputDateStatusSecond:'input-border-style-success',
        subErrMessageSecond:'',
        subErrHanDonSecond:false,
        effectiveFrom2018To2023:false,
        inputDateStatusThird:'input-border-style-success',
        subErrMessageThird:'',
        subErrHanDonThird:false,
        investmentAmountFrom2003To2008: '',
        investmentAmountTotalFrom2003To2008: '',
        investmentAmountFrom2008To2018: '',
        investmentAmountTotalFrom2008To2018: '',
        investmentAmountFrom2018To2023: '',
        investmentAmountTotalFrom2018To2023: '',
        instructions: ''
      }
      
      ],

      resultInvestMoney:'',

      options: [{
        value: 'changeOne',
        label: '安全重視派：年利回り3%程度'
      }, {
        value: 'changeTwo',
        label: '中間派：年利回り3～8%'
      }, {
        value: 'changeThird',
        label: '積極派：年利回り8%以上'
      }
      ],

      select:'',
      submitStatus:{
        submitFirst: true,
        subErrMessageFirst:'',
        submitSecond: true,
        subErrMessageSecond:'',
        submitThird: true,
        subErrMessageThird:'',
      },

      buttonSubmit:false


    }

  }
}
</script>