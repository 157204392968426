<template>

  <div class="screen-layout">
    <div >
      <div class="buttonGroup">
        <div class="button"><el-button type="warning" icon="el-icon-refresh" @click="dataRefresh" round>データ再表示</el-button></div>
        <div class="button"><el-button type="success" icon="el-icon-edit" @click="openDialog" :disabled="this.seminar.expPeriodFlag!==0" round>2003年～2008年終了し計算実行</el-button></div>
        <div class="button"><el-button type="success" icon="el-icon-edit" @click="openDialog" :disabled="this.seminar.expPeriodFlag!==1" round>2008年～2018年終了し計算実行</el-button></div>
        <div class="button"><el-button type="success" icon="el-icon-edit" @click="openDialog" :disabled="this.seminar.expPeriodFlag!==2" round>2018年～2023年終了し計算実行</el-button></div>
        <div class="button"><el-button type="danger" icon="el-icon-delete" @click="studentMsgClear = true" round>授業終了し生徒入力クリア</el-button></div>
        <el-dialog
            title="確認"
            :visible.sync="studentMsgClear"
            width="30%"
            :before-close="handleClose">
          <span>生徒の入力データを削除してもよろしいですか？</span>
          <span slot="footer" class="dialog-footer">
            <el-button @click="studentMsgClear = false">取消</el-button>
            <el-button type="primary" @click="dataClear">確定</el-button>
          </span>
        </el-dialog>


      </div>
    </div>

    <el-dialog
        title="確認"
        :visible.sync="dialogVisible"
        width="30%"
        :before-close="handleClose">
      <span>投資終了して計算を実行してもよろしいですか？</span>
      <span slot="footer" class="dialog-footer">
      <el-button @click="dialogVisible = false">取消</el-button>
      <el-button type="primary" @click="confirm">確定</el-button>
    </span>
    </el-dialog>

    <el-dialog
        title="エラー"
        :visible.sync="finalCalculationTips"
        width="30%">
      <span>エラーが発生しました！授業フローを確認した上、再開してください。</span>
      <span slot="footer" class="dialog-footer">
      <el-button @click="finalCalculationTips = false">確定</el-button>
    </span>
    </el-dialog>

    <el-table
        empty-text="入力データがありません"
        :max-height="tableHeight"
        :data="stuAssetsMsgList"
        style="width: 100%;"
        :header-cell-style="{background:'#409EFF',color:'#fcf7f7'}"
        :show-body="false"
    >

      <el-table-column
          prop="stuNo"
          label="受講者"
          width="100"
          :cell-style="rowStyle"
          align="center"
      >
        <template v-slot="scope">
          <el-button
              size="small"
              icon="el-icon-view"
              type="primary"
              prop="student"
              @click="msgView(scope.row.stuNo)"
           >{{scope.row.stuNo}}
          </el-button>

        </template>

      </el-table-column>

      <el-table-column
          prop="intendedRate"
          label="投信スタイル"
          width="300"
          :cell-style="rowStyle"
          align="center"
      >
      </el-table-column>

      <el-table-column label="2003年～2008年"
                       align="center">


        <el-table-column
            prop="investFrom2003To2008Start"
            label="スタート"
            width="150"
            align="center"
        >
           <template v-slot="scope">
             <span>{{formatNumberInput(scope.row.investFrom2003To2008Start)}}</span>
           </template>
        </el-table-column>

        <el-table-column
            prop="investFrom2003To2008End"
            label="投資後" 
            width="150"
            align="center">

          <template v-slot="scope">
            <span v-show="seminar.expPeriodFlag>=1">{{formatNumberInput(scope.row.investFrom2003To2008End)}}</span>
          </template>
        </el-table-column>

        <el-table-column
            prop="statusFlgFrom2003To2008"
            label="入力状況"
            width="120"
            align="center">
          <template v-slot="scope">
            <div class="tag-group">
            <span v-show="scope.row.statusFlgFrom2003To2008 === true">
            <el-tag
                type=""
                effect="light">
              投資確定
            </el-tag>
              </span>
              <span  v-show="scope.row.statusFlgFrom2003To2008 !== true">
              <el-tag type="danger" effect="light">未</el-tag>
            </span>
            </div>

          </template>

        </el-table-column>

      </el-table-column>


      <el-table-column label="2008年～2018年" align="center">
        <el-table-column
            prop="investFrom2008To2018Start"
            label="スタート"
            width="150"
            align="center">

          <template v-slot="scope">
            <span v-show="seminar.expPeriodFlag===0"></span>
            <span v-show="seminar.expPeriodFlag>=1">{{formatNumberInput(scope.row.investFrom2008To2018Start)}}</span>
          </template>
        </el-table-column>
        <el-table-column
            prop="investFrom2008To2018End"
            label="投資後"
            width="150"
            align="center">

          <template v-slot="scope">
            <span v-show="seminar.expPeriodFlag>=2">{{formatNumberInput(scope.row.investFrom2008To2018End)}}</span>
          </template>
        </el-table-column>
        <el-table-column
            prop="statusFlgFrom2008To2018"
            label="入力状況"
            width="120"
            align="center">

          <template v-slot="scope" >
            <div class="tag-group">
            <span v-show="scope.row.statusFlgFrom2008To2018 === true&&seminar.expPeriodFlag>=1">
            <el-tag
                type=""
                effect="light">
              投資確定
            </el-tag>
              </span>
            <span  v-show="scope.row.statusFlgFrom2008To2018 !== true&&seminar.expPeriodFlag>=1">
              <el-tag type="danger" effect="light">未</el-tag>
            </span>
              <span v-show="seminar.expPeriodFlag===0"></span>
            </div>
          </template>

        </el-table-column>

      </el-table-column>

      <el-table-column label="2018年～2023年" align="center">
        <el-table-column
            prop="investFrom2018To2023Start"
            label="スタート"
            width="150"
            align="center">
          <template v-slot="scope">
            <span v-show="seminar.expPeriodFlag>=2">{{formatNumberInput(scope.row.investFrom2018To2023Start)}}</span>
          </template>
        </el-table-column>
        <el-table-column
            prop="investFrom2018To2023End"
            label="投資後"
            width="150"
            align="center">
          <template v-slot="scope">
            <span v-show="seminar.expPeriodFlag>=3">{{formatNumberInput(scope.row.investFrom2018To2023End)}}</span>
          </template>
        </el-table-column>

        <el-table-column
            prop="statusFlgFrom2018To2023"
            label="入力状況"
            width="120"
            align="center">

          <template v-slot="scope">
            <div class="tag-group">
            <span v-show="scope.row.statusFlgFrom2018To2023 === true&&seminar.expPeriodFlag>=2">
            <el-tag
                type=""
                effect="light">
              投資確定
            </el-tag>
              </span>
              <span  v-show="scope.row.statusFlgFrom2018To2023 !== true&&seminar.expPeriodFlag>=2">
              <el-tag type="danger" effect="light">未</el-tag>
            </span>
            </div>

          </template>

        </el-table-column>
      </el-table-column>
      <el-table-column
          prop="resultInvest"
          label="最終結果"
          width="150"
          align="center">
        <template v-slot="scope">
          <span v-show="seminar.expPeriodFlag>=3">{{formatNumberInput(scope.row.resultInvest)}}</span>
        </template>
      </el-table-column>
      <el-table-column
          prop="yield"
          label="年利回り"
          width="90"
          >
        <template  v-slot="scope">
            <span v-show="seminar.expPeriodFlag===1">
            {{scope.row.yieldFrom2003To2008}}%
            </span>

          <span v-show="seminar.expPeriodFlag===2">
            {{scope.row.yieldFrom2008To2018}}%
            </span>

          <span v-show="seminar.expPeriodFlag===3">
            {{scope.row.yieldFrom2018To2023}}%
            </span>
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>

<style >

.buttonGroup{
  margin-top: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 15px;
  .button{
    width: 33.33%;
    display: flex;
    justify-content: center;
  }
}

.screen-layout{
  max-height: 100vh;
  height: 100vh;
  overflow-y: hidden;
  overflow-x: auto;

}

</style>

<script>
import axios from "axios";
export default {

  mounted() {
    document.title = '結果照会';
    this.screenHeight = document.body.clientHeight;
    window.onresize = () => {
      return (() => {
        this.screenHeight = document.body.clientHeight;
      })();
    };

  },

  watch: {
    screenHeight: {
      handler: function (val) {
        this.tableHeight = val - 80;
      },
      immediate: true,
      deep:true
    },
  },
  created(){
    this.openLoadingScreen()

    this.stuAssetsMsgList=null

    axios.post("/getAllStudentMsg",
    ).then(response => {
         this.closeLoadingScreen()

         this.stuAssetsMsgList = response.data.stuAssetsMsgList
         this.seminar = response.data.seminar

    })
        .catch(error => {
          console.log(error)
        });
  },

  methods: {
    openLoadingScreen() {
      this.$loading({
        lock: true,
        text: '画面ロード中...',
        spinner: 'el-icon-loading',
        background: 'rgba(255, 255, 255, 255)'
      });
    },

    closeLoadingScreen(options){
      this.$loading(options).close();
    },


    formatNumberInput(value){
      if(value===null){
        return value
      }else if(typeof value !=='number'){
        value = value.replace(/,/g, '');
      }
      return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');

    },

    dataRefresh(){
      axios.post("/getAllStudentMsg",
      ).then(response => {
        this.stuAssetsMsgList = response.data.stuAssetsMsgList

        this.seminar = response.data.seminar

      })
          .catch(error => {
            console.log(error)
          });
    },

    rowStyle() {
      return "text-align:center";
    },
    openDialog() {
      this.dialogVisible = true;
    },
    handleClose(done) {
      done();
    },
    confirm() {

      this.dialogVisible = false;

      axios.post( "/finalCalculation",
          {
            seminarId: this.seminar.seminarId,
            investAmount:this.seminar.investAmount,
            expPeriodFlag:this.seminar.expPeriodFlag
          },
      ).then(response => {

        if (response.data.seminar.expPeriodFlag > this.seminar.expPeriodFlag){

          this.stuAssetsMsgList = response.data.stuAssetsMsgList

          this.seminar = response.data.seminar

        }else {
          this.$message.error('エラーが発生しました！授業フローを確認した上、再開してください。');
        }

      })
          .catch(error => {
            console.log(error)
          });
    },

    dataClear(){

      if (this.seminar === null){
        this.$message.error('エラーが発生しました！授業フローを確認した上、再開してください。');
      }

      axios.post("/dataClear",
          {
            seminarId: this.seminar.seminarId,
            investAmount:this.seminar.investAmount,
            expPeriodFlag:this.seminar.expPeriodFlag
          },
      ).then(response => {

          this.studentMsgClear = false
          this.stuAssetsMsgList = response.data.stuAssetsMsgList
          this.seminar = response.data.seminar

      })
          .catch(error => {
            console.log(error)
          });


    },

    msgView(stuNo){

      const seminarId = this.seminar.seminarId

      const url = `/#/stuPictureCon/${encodeURIComponent(seminarId)}/${encodeURIComponent(stuNo)}`;

      window.open(url, '_blank');
    }


  },
  data() {
    return {
      tableHeight:880,
      screenHeight:0,
      seminar: {
        seminarId: '',
        investAmount: 0,
        expPeriodFlag: 0
      },

      stuAssetsMsgList: [
          {
        stuId: '',
        stuNo: '001',
        seminarId: '',
        intendedRate: '',
        investFrom2003To2008Start: 0,
        fndFrom2003To2008Start: 0,
        cashFrom2003To2008Start: 0,
        investFrom2003To2008End: 0,
        fndFrom2003To2008End: 0,
        cashFrom2003To2008End: 0,
        investFrom2008To2018Start: 0,
        fndFrom2008To2018Start: 0,
        cashFrom2008To2018Start: 0,
        investFrom2008To2018End: 0,
        fndFrom2008To2018End: 0,
        cashFrom2008To2018End: 0,
        investFrom2018To2023Start: 0,
        fndFrom2018To2023Start: 0,
        cashFrom2018To2023Start: 0,
        investFrom2018To2023End: 0,
        fndFrom2018To2023End: 0,
        cashFrom2018To2023End: 0,
        statusFlgFrom2003To2008: false,
        statusFlgFrom2008To2018: false,
        statusFlgFrom2018To2023: false,
        resultInvest: 0,
        resultCash: 0,
        yieldFrom2003To2008:0,
        yieldFrom2008To2018:0,
        yieldFrom2018To2023:0
  },],

      dialogVisible: false,
      finalCalculationTips:false,

      options: [{
        label1: '安定派：年利回り3%以下'
      ,
        label2: '中間派：年利回り3~8%'
      ,
        label3: '積極派：年利回り8％以上'
      }
      ],

      studentMsgClear:false

    }
  }
}
</script>