import Vue from 'vue'

import Router from 'vue-router'

import ExperienceScreen from '@/views/ExperienceScreen.vue'
import PrepareEnter from '@/views/PrepareEnter.vue'
import ResultScreen from  '@/views/ResultScreen.vue'
import StuPictureCon from '@/views/StuPictureCon.vue'
import ExperienceScreenAgo from "@/views/ExperienceScreenAgo.vue";

Vue.use(Router)


let routes =[
    {
    path: '/prepare',
    component: PrepareEnter
    },
    {
        path: '/experience/:seminarId/:stuNo',
        name: "experience",
        component: ExperienceScreen
    },

    {
        path: '/experienceAgo/:seminarId/:stuNo',
        name: "experienceAgo",
        component: ExperienceScreenAgo
    },


    {
        path: '/resultScreen',
        component: ResultScreen
    }
    ,
    {
        path: '/stuPictureCon/:seminarId/:stuNo',
        name: "stuPic",
        component: StuPictureCon
    },
    ];

const router=new Router({

    mode :'hash',
    routes
})

export default router

