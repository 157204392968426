<template>
  <div>
    <div class="flex align-center justify-content-center flex-end option-group">
      <div class="option-item">
        <div class="label f14"> 投資スタイルを選ぼう！</div>
        <el-select
            class="input-border-style"
            @change="selectChange"
            v-model="select" placeholder="選択してください" >
          <el-option
              class="f14"
              v-for="item in options"
              :key="item.value"
              :label="item.label"
              :value="item.label"
          >
          </el-option>
        </el-select>
      </div>
      <div class="title option-item center f14"  v-show="seminar.expPeriodFlag ===0"> 2003年～2008年：投資してみよう</div>
      <div class="title option-item center f14"  v-show="seminar.expPeriodFlag ===1"> 2008年～2018年：投資してみよう</div>
      <div class="title option-item center f14"  v-show="seminar.expPeriodFlag ===2"> 2018年～2023年：投資してみよう</div>
      <div class="title option-item center f14"  v-show="seminar.expPeriodFlag ===3"> 最終結果</div>

      <div class="option-item center"><el-button class="f14" type="success" v-show="seminar.expPeriodFlag<3"  @click="handleButtonClick" icon="el-icon-edit" round>投資確定</el-button></div>
      <div class="option-item center"><el-button class="f14" type="success" v-show="seminar.expPeriodFlag<3"  @click="handleButtonClickContinue"  icon="el-icon-right" round>次の年代へ</el-button></div>
      <div class="option-item flex flex-end align-center">
        <div class="left">
          <div class="label f14"> あなたの最終結果</div>
          <div class="border-color-red bg-color-theme messageBox" v-show="seminar.expPeriodFlag===3">
            <div class="content-center f14" >
              {{resultInvestMoney}}
            </div>
          </div>
          <div>
            <div class="border-color-red bg-color-theme messageBox" v-show="seminar.expPeriodFlag!==3">
              <div class="content-center" >
              </div>
            </div>
          </div>

        </div>
      </div>
      <div class="option-item  flex flex-end align-center">
        <div class="left">
          <div class="label f14"> あなたの年利回りは</div>
          <div class="border-color-red bg-color-theme messageBox" >
            <div class="content-center f14" v-show="seminar.expPeriodFlag===1">
              {{formattedYield2003To2008}}
            </div>

            <div class="content-center f14" v-show="seminar.expPeriodFlag===2">
              {{formattedYield2008To2018}}
            </div>

            <div class="content-center f14" v-show="seminar.expPeriodFlag===3">
              {{formattedYield2018To2023}}
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="table-container">


      <el-table
          empty-text="入力データがありません"
          :data="stuAssetsMsgList"
          border
          max-height="400"
          :cell-style="tableRowClassHeader"
          :header-cell-style="{background:'#409EFF',color:'#fcf7f7'}"
          style="width: 100%"
      >
        <el-table-column
            label-class-name="cell-top"
            label="分類"
            width="300"
            :cell-style="rowStyle"
            align="center"
        >
        </el-table-column>

        <el-table-column
            label="2003年～2008年"

            align="center">
          <el-table-column
              prop="investFrom2003To2008Start"
              label="スタート"
              width="170"
              header-align="center"
              align="center"
          >
            <template v-slot="scope">
              <div class="bold-text">投資可能額:</div>
              <div class="bold-text">{{formatNumber(scope.row.investFrom2003To2008Start)}}</div>

            </template>
          </el-table-column>

          <el-table-column
              prop="investFrom2003To2008End"
              label="投資後"
              width="170"
              align="center">

            <template>
              <span></span>

            </template>

          </el-table-column>

        </el-table-column>
        <el-table-column label="2008年～2018年" align="center">
          <el-table-column
              prop="investFrom2008To2018Start"
              label="スタート"
              width="170"
              align="center">

            <template v-slot="scope">
              <span v-show="seminar.expPeriodFlag<1"></span>

              <div v-show="seminar.expPeriodFlag>=1">
                <div class="bold-text">投資可能額:</div>
                <div class="bold-text">{{formatNumber(scope.row.investFrom2008To2018Start)}}</div>
              </div>
            </template>

          </el-table-column>
          <el-table-column
              prop="investFrom2008To2018End"
              label="投資後"
              width="170"
              disabled="none"
              align="center">
            <template>
              <span></span>
            </template>


          </el-table-column>
        </el-table-column>

        <el-table-column label="2018年～2023年" align="center">
          <el-table-column
              prop="investFrom2018To2023Start"
              label="スタート"
              width="170"
              align="center">
            <template v-slot="scope">

              <div v-show="seminar.expPeriodFlag>=2">
                <div class="bold-text">投資可能額:</div>
                <div class="bold-text">{{formatNumber(scope.row.investFrom2018To2023Start)}}</div>
              </div>
              <span v-show="seminar.expPeriodFlag<2"></span>

            </template>

          </el-table-column>
          <el-table-column
              prop="investFrom2018To2023End"
              label="投資後"
              width="170"
              align="center">

            <template>
              <span></span>

            </template>
          </el-table-column>
        </el-table-column>
        <el-table-column
            prop="explain"
            label="説明"

            align="center">
        </el-table-column>
      </el-table>
    </div>

    <div class="table-container">
      <el-table
          empty-text="入力データがありません"
          :show-header="false"
          :max-height="620"
          border
          :data="stuInvestmentMsgDisplayList"
          style="width: 100%;"

          :header-cell-style="{background:'#5b92cc',color:'#fcf7f7'}">

        <el-table-column
            v-if="false"
            prop="kindId"
            label="分類"
            :cell-style="rowStyle"
            width="300"
        >

        </el-table-column>

        <el-table-column

            prop="kindName"
            label="分類"
            :cell-style="rowStyle"
            width="300"
        >
          <template v-slot="scope">
            <span v-html="convertNewLines(scope.row.kindName)"></span>
          </template>
        </el-table-column>

        <el-table-column label="2003年～2008年"
                         align="center">
          <el-table-column

              prop="investmentAmountFrom2003To2008"
              label="スタート"
              width="170"
              align="center"
          >
            <template v-slot="scope">

              <span v-show="!scope.row.effectiveFrom2003To2008"></span>

              <span v-show="scope.row.effectiveFrom2003To2008&&seminar.expPeriodFlag>0">{{formatNumber(scope.row.investmentAmountFrom2003To2008)}} </span>
              <el-input

                  class="input-border-style"
                  :controls ="false"
                  style="width: 125px;"
                  @input="handleInput2003To2008Check(scope.row)"
                  @change="handleInput2003To2008"
                  v-show="scope.row.effectiveFrom2003To2008&& seminar.expPeriodFlag===0"
                  v-model="scope.row.investmentAmountFrom2003To2008"
                  size="medium"
              />

            </template>

          </el-table-column>

          <el-table-column
              prop="investmentAmountTotalFrom2003To2008"
              label="投資後"
              width="170"
              align="center">
            <template v-slot="scope">
              <span v-show="!scope.row.effectiveFrom2003To2008"></span>
              <span v-show="scope.row.effectiveFrom2003To2008&&seminar.expPeriodFlag>0">{{formatNumber(scope.row.investmentAmountTotalFrom2003To2008)}} </span>

              <span v-show="scope.row.effectiveFrom2003To2008&&seminar.expPeriodFlag===0"></span>


            </template>
          </el-table-column>
        </el-table-column>
        <el-table-column label="2008年～2018年" align="center">
          <el-table-column
              prop="investmentAmountFrom2008To2018"
              label="スタート"
              width="170"
              align="center">
            <template v-slot="scope">

              <span v-show="!scope.row.effectiveFrom2008To2018"></span>
              <span v-show="(scope.row.effectiveFrom2008To2018)&&(seminar.expPeriodFlag>1)">{{formatNumber(scope.row.investmentAmountFrom2008To2018)}} </span>
              <el-input
                  class="input-border-style"
                  :controls ="false"
                  style="width: 125px;text-align: right;"
                  @input="handleInput2008To2018Check(scope.row)"
                  @change="handleInput2008To2018(scope.row)"
                  v-show="scope.row.effectiveFrom2008To2018&&seminar.expPeriodFlag===1"
                  v-model="scope.row.investmentAmountFrom2008To2018"
                  size="medium"
              />

            </template>

          </el-table-column>
          <el-table-column
              prop="investmentAmountTotalFrom2008To2018"
              label="投資後"
              width="170"
              align="center">

            <template v-slot="scope">

              <span v-show="!scope.row.effectiveFrom2008To2018"></span>
              <span v-show="scope.row.effectiveFrom2008To2018&&seminar.expPeriodFlag>1">{{formatNumber(scope.row.investmentAmountTotalFrom2008To2018)}} </span>

              <span v-show="seminar.expPeriodFlag<=1"></span>


            </template>
          </el-table-column>
        </el-table-column>

        <el-table-column label="2018年～2023年">
          <el-table-column
              prop="investmentAmountFrom2018To2023"
              label="スタート"
              width="170"
              align="center">
            <template v-slot="scope">

              <span v-show="(!scope.row.effectiveFrom2018To2023)"></span>
              <span v-show="(scope.row.effectiveFrom2018To2023)&&(seminar.expPeriodFlag>2)">{{formatNumber(scope.row.investmentAmountFrom2018To2023)}} </span>
              <el-input
                  class="input-border-style"
                  :controls ="false"
                  @input="handleInput2018To2023Check(scope.row)"
                  @change="handleInput2018To2023(scope.row)"
                  style="width: 125px;"
                  v-show="scope.row.effectiveFrom2018To2023&&seminar.expPeriodFlag===2"
                  v-model="scope.row.investmentAmountFrom2018To2023"
                  size="medium"

              />
            </template>

          </el-table-column>
          <el-table-column
              prop="investmentAmountTotalFrom2018To2023"
              label="投資後"
              width="170"
              align="center">

            <template v-slot="scope">
              <span v-show="(!scope.row.effectiveFrom2018To2023)"></span>
              <span v-show="scope.row.effectiveFrom2018To2023&&seminar.expPeriodFlag>2">{{formatNumber(scope.row.investmentAmountTotalFrom2018To2023)}} </span>

              <span v-show="seminar.expPeriodFlag<=2"></span>


            </template>
          </el-table-column>
        </el-table-column>
        <el-table-column
            prop="instructions"
            label="説明"
        >
          <template v-slot="scope">

            <span class="f11">{{scope.row.instructions}}</span>

          </template>

        </el-table-column>

      </el-table>
    </div>
    <el-table
        empty-text="入力データがありません"
        :show-header="false"
        :data="stuAssetsMsgList"
        border
        height="50"
        :cell-style="tableRowClassEnd"
        :header-cell-style="{background:'#5b92cc',color:'#fcf7f7'}"
        style="width: 100%"
    >
      <el-table-column
          prop="kind"
          label="分類"
          :cell-style="rowStyle"
          width="300"
          align="center"
      >
        <template>
          <span class="bold-text">
            現金(投資可能残)
          </span>
        </template>
      </el-table-column>

      <el-table-column label="2003年～2008年"
                       align="center">
        <el-table-column
            label="スタート"
            width="170"
            align="center"
        >
          <template v-slot="scope">
            <div class="bold-text">{{formatNumber(scope.row.cashFrom2003To2008Start)}}</div>
          </template>

        </el-table-column>

        <el-table-column
            prop="cashFrom2003To2008End"
            label="投資後"
            width="170"
            align="center">

          <template v-slot="scope">
            <span class="bold-text" v-show="seminar.expPeriodFlag>0">{{formatNumber(scope.row.cashFrom2003To2008End)}} </span>

            <span v-show="seminar.expPeriodFlag===0"></span>


          </template>

        </el-table-column>
      </el-table-column>
      <el-table-column label="2008年～2018年" align="center">
        <el-table-column
            prop="cashFrom2008To2018Start"
            label="スタート"
            width="170"

            align="center">
          <template v-slot="scope">
            <span class="bold-text" v-show="seminar.expPeriodFlag>=1" >{{formatNumber(scope.row.cashFrom2008To2018Start)}} </span>

            <span v-show="seminar.expPeriodFlag<1"></span>


          </template>


        </el-table-column>
        <el-table-column
            prop="cashFrom2008To2018End"
            label="投資後"
            width="170"

            align="center">
          <template v-slot="scope">
            <span class="bold-text" v-show="seminar.expPeriodFlag>=2">{{formatNumber(scope.row.cashFrom2008To2018End)}} </span>

            <span v-show="seminar.expPeriodFlag<2"></span>


          </template>

        </el-table-column>
      </el-table-column>

      <el-table-column label="2018年～2023年" align="center">
        <el-table-column
            prop="cashFrom2018To2023Start"
            label="スタート"
            width="170"

            align="center">
          <template v-slot="scope">
            <span class="bold-text" v-show="seminar.expPeriodFlag>=2">{{formatNumber(scope.row.cashFrom2018To2023Start)}} </span>

            <span v-show="seminar.expPeriodFlag<2"></span>

          </template>

        </el-table-column>
        <el-table-column
            prop="cashFrom2018To2023End"
            label="投資後"
            width="170"

            align="center">
          <template v-slot="scope">
            <span class="bold-text" v-show="seminar.expPeriodFlag>=3">{{formatNumber(scope.row.cashFrom2018To2023End)}} </span>

            <span v-show="seminar.expPeriodFlag<3"></span>


          </template>

        </el-table-column>
      </el-table-column>
      <el-table-column
          prop="explain"
          label="説明"
          align="center"
      >
      </el-table-column>
    </el-table>


  </div>

</template>

<style scoped>

.instructions{
  font-size: 2rem;
}
.el-table .success-row {
  background: #f0f9eb;
}
.title{
  margin-top: 1rem;
}
.option-item{
  width: 20%;
}
.label{
  margin-bottom: 2px;
}
.left{
  .label{
    color: crimson;
  }
}
.option-group{
  margin: 1rem 0.5rem;

}
.end-char{
  margin-left: 1rem;
}

.input-border-style{
  border:1px solid #409EFF;
  border-radius:6px;

}
.el-table__row td:last-child div.cell{
  font-size: 2rem !important;
}

.messageBox{
  display: flex;
  align-items: center;
  padding-left: 1rem;
  height: 35px;
  width: 200px;
  border-radius:3px;

}
.content-center{
  color: crimson;
}

.el-input__inner{
  font-size: 10rem !important;
}
</style >

<script>
import axios from "axios";

export default {
  mounted() {
    document.title = '投資体験';
  },
  created(){

    axios.post('http://192.168.110.49:8081/experienceDateGet',
        {
          seminarIdNo: this.$route.params.seminarId,
          studentNo:this.$route.params.stuNo
        },

    ).then(response => {

      if(response.data === null){
        this.$router.push('/experience');
      }else {

        this.seminar= response.data.seminar;
        this.stuAssetsMsgList= response.data.stuAssetsMsgList;

        this.stuInvestmentMsgList=response.data.stuInvestmentMsgList;

        this.resultInvestMoney=this.formatNumberInput(this.stuAssetsMsgList[0].resultInvest)

        this.select=this.stuAssetsMsgList[0].intendedRate

        this.formatStuInvestmentMsgToString();
      }

    })
        .catch(error => {

          console.log(error)
        });
  },

  computed: {
    formattedYield2003To2008() {

      if(this.stuAssetsMsgList[0].yieldFrom2003To2008!=null){
        return this.stuAssetsMsgList[0].yieldFrom2003To2008+ '%';
      }else {
        return 0+'%'
      }
    },
    formattedYield2008To2018() {

      if(this.stuAssetsMsgList[0].yieldFrom2008To2018!=null){
        return this.stuAssetsMsgList[0].yieldFrom2008To2018+ '%';
      }else {
        return 0+'%'
      }
    },

    formattedYield2018To2023() {

      if(this.stuAssetsMsgList[0].yieldFrom2018To2023!=null){
        return this.stuAssetsMsgList[0].yieldFrom2018To2023+ '%';
      }else {
        return 0+'%'
      }
    },
  },

  methods: {
    tableRowClassHeader({rowIndex}) {
      if (rowIndex === 0) {
        return "background:#409EFF;color: #FFFFFF;"
      }
      return '';
    },


    formatNumberInput(value){

      if(value===null){
        return '0'
      }
      value = value.toString().replace(/,/g, '');
      return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');

    },

    parseNumberInput(value){
      if (value===null || value ===''){
        return Number(0)
      }
      else {
        return Number(value.toString().replace(/,/g, ''));
      }
    },

    handleInput2003To2008Check(value){
      const regex = /^\d+$/;
      const  wkInvestmentAmountFrom = value.investmentAmountFrom2003To2008.replace(/,/g, '')

      if (!regex.test(wkInvestmentAmountFrom)&&wkInvestmentAmountFrom!==''){
        alert("データタイプの入力をお願いしますので,再入力をお願いします")
        value.investmentAmountFrom2003To2008=''
      }

      if (Number(value.investmentAmountFrom2003To2008) < 0){
        alert("正の整数を入力しますので,再入力をお願いします")
        value.investmentAmountFrom2003To2008=''
      }

      if (Number(value.investmentAmountFrom2003To2008) >= 999999999999999){
        alert("ご入力のデータは大きすぎますので,再入力をお願いします")
        value.investmentAmountFrom2003To2008=''
      }


      this.parseNumberInput(value.investmentAmountFrom2003To2008)

      value.investmentAmountFrom2003To2008= this.formatNumberInput(value.investmentAmountFrom2003To2008)
    },

    handleInput2008To2018Check(value){

      const regex = /^\d+$/;
      const  wkInvestmentAmountFrom = value.investmentAmountFrom2008To2018.replace(/,/g, '')

      if (!regex.test(wkInvestmentAmountFrom)&&wkInvestmentAmountFrom!==''){

        alert("データタイプの入力をお願いしますので,再入力をお願いします")
        value.investmentAmountFrom2008To2018=''
      }

      if (value.investmentAmountFrom2008To2018 < 0){
        alert("正の整数を入力しますので,再入力をお願いします")
        value.investmentAmountFrom2008To2018=''
      }

      if (value.investmentAmountFrom2008To2018 >= 999999999999999){
        alert("ご入力のデータは大きすぎますので,再入力をお願いします")
        value.investmentAmountFrom2008To2018=''
      }

      this.parseNumberInput(value.investmentAmountFrom2008To2018)
      value.investmentAmountFrom2008To2018= this.formatNumberInput(value.investmentAmountFrom2008To2018)

    },

    handleInput2018To2023Check(value){

      const regex = /^\d+$/;
      const  wkInvestmentAmountFrom = value.investmentAmountFrom2018To2023.replace(/,/g, '')

      if (!regex.test(wkInvestmentAmountFrom)&&wkInvestmentAmountFrom!==''){

        alert("データタイプの入力をお願いしますので,再入力をお願いします")
        value.investmentAmountFrom2018To2023=''
      }

      if (value.investmentAmountFrom2018To2023 < 0){
        alert("正の整数を入力しますので,再入力をお願いします")
        value.investmentAmountFrom2018To2023=''
      }

      if (value.investmentAmountFrom2018To2023 >= 999999999999999){
        alert("ご入力のデータは大きすぎますので,再入力をお願いします")
        value.investmentAmountFrom2018To2023=''
      }

      this.parseNumberInput(value.investmentAmountFrom2018To2023)
      value.investmentAmountFrom2018To2023= this.formatNumberInput(value.investmentAmountFrom2018To2023)
    },



    handleInput2003To2008(){
      this.formatStuInvestmentMsgToNumber();
      let totalSum = this.stuInvestmentMsgList.reduce((accumulator, currentValue) => {

        const amount = typeof currentValue.investmentAmountFrom2003To2008 === 'number' ? currentValue.investmentAmountFrom2003To2008 : 0;
        return accumulator + amount;
      }, 0);

      let subtraction =this.stuAssetsMsgList[0].investFrom2003To2008Start-totalSum;

      if (subtraction<0){
        alert("投資可能額よりも投資金額が大きい、チェックしてください。")

      }else {
        this.stuAssetsMsgList[0].cashFrom2003To2008Start=subtraction
        this.stuAssetsMsgList[0].cashFrom2003To2008End=subtraction

        console.log(this.stuAssetsMsgList[0].cashFrom2003To2008Start)
        console.log(this.stuAssetsMsgList[0].cashFrom2003To2008Start)

        axios.post('http://192.168.110.49:8081/experienceFirstComputeFirst',
            {
              seminar:this.seminar,
              stuAssetsMsgList: this.stuAssetsMsgList,
              stuInvestmentMsgList:this.stuInvestmentMsgList,
            },
        )

      }

    },

    handleInput2008To2018(){

      this.formatStuInvestmentMsgToNumber();

      let totalSum = this.stuInvestmentMsgList.reduce((accumulator, currentValue) => {

        const amount = typeof currentValue.investmentAmountFrom2008To2018 === 'number' ? currentValue.investmentAmountFrom2008To2018 : 0;
        return accumulator + amount;
      }, 0);

      let subtraction =this.stuAssetsMsgList[0].investFrom2008To2018Start-totalSum;


      if (subtraction<0){
        alert("投資可能額よりも投資金額が大きい、チェックしてください。")

      }
      else {
        this.stuAssetsMsgList[0].cashFrom2008To2018Start=subtraction
        this.stuAssetsMsgList[0].cashFrom2008To2018End=subtraction

        axios.post('http://192.168.110.49:8081/experienceComputeSecond',
            {
              seminar:this.seminar,
              stuAssetsMsgList: this.stuAssetsMsgList,
              stuInvestmentMsgList:this.stuInvestmentMsgList,
            },
        )

      }
    },

    handleInput2018To2023(){

      this.formatStuInvestmentMsgToNumber()

      let totalSum = this.stuInvestmentMsgList.reduce((accumulator, currentValue) => {

        const amount = typeof currentValue.investmentAmountFrom2018To2023 === 'number' ? currentValue.investmentAmountFrom2018To2023 : 0;
        return accumulator + amount;
      }, 0);

      let subtraction =this.stuAssetsMsgList[0].investFrom2018To2023Start-totalSum;


      if (subtraction<0){
        alert("投資可能額よりも投資金額が大きい、チェックしてください。")

      }
      else {
        this.stuAssetsMsgList[0].cashFrom2018To2023Start=subtraction
        this.stuAssetsMsgList[0].cashFrom2008To2018End=subtraction
        axios.post('http://192.168.110.49:8081/experienceComputeThird',
            {
              seminar:this.seminar,
              stuAssetsMsgList: this.stuAssetsMsgList,
              stuInvestmentMsgList:this.stuInvestmentMsgList,
            },
        )

      }
    },
    selectChange(){

       this.stuAssetsMsgList[0].intendedRate=this.select
      axios.post('http://192.168.110.49:8081/experienceSelectChange',
          {
            stuAssetsMsgList: this.stuAssetsMsgList,
          },
      )
    },

    formatNumber(value) {
      if (typeof value !== 'number') {
        return value;
      }
      return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    },

    handleButtonClick(){

      axios.post('http://192.168.110.49:8081/confirmInvestment ',
          {
            stuAssetsMsgList: this.stuAssetsMsgList,
            stuInvestmentMsgList:this.stuInvestmentMsgList,
            seminar:this.seminar
          },
      )

      if(this.seminar.expPeriodFlag === 0){
        alert("2003年～2008年投資確認しました")
      }

      if (this.seminar.expPeriodFlag === 1){
        alert("2008年～2018年投資確認しました")
      }

      if (this.seminar.expPeriodFlag === 2){
        alert("2018年～2023年投資確認しました")
      }
    },

    convertNewLines(text) {
      return text.replace(/\n/g, '<br>');
    },

    handleButtonClickContinue(){
      axios.post('http://192.168.110.49:8081/experienceContinue ',
          {
            seminarIdNo: this.seminar.seminarId,
            studentNo:this.stuAssetsMsgList[0].stuNo,
          },
      ).then(response => {

        if(response.data.seminar.expPeriodFlag===this.seminar.expPeriodFlag){

          if (response.data.seminar.expPeriodFlag ===0){

            alert("2003年～2008年：投資しています。")
          }else if (response.data.seminar.expPeriodFlag ===1){

            alert("2008年～2018年：投資しています。")
          }else if (response.data.seminar.expPeriodFlag ===2){

            alert("2018年～2023年：投資しています。")
          }

        }else {
          this.seminar= response.data.seminar;
          this.stuAssetsMsgList= response.data.stuAssetsMsgList;
          this.stuInvestmentMsgList=response.data.stuInvestmentMsgList;
          this.select=this.stuAssetsMsgList[0].intendedRate
          this.resultInvestMoney=this.formatNumberInput(this.stuAssetsMsgList[0].resultInvest)
          this.formatStuInvestmentMsgToString(response.data.stuInvestmentMsgList);
        }
      })
          .catch(error => {

            console.log(error)
          });
    },

    formatStuInvestmentMsgToNumber(){
      this.stuInvestmentMsgList.splice(0, this.stuInvestmentMsgList.length);


      this.stuInvestmentMsgDisplayList.forEach(item => {

        const newItem = {
          stuNo: item.stuNo,
          kindId: item.kindId,
          kindName: item.kindName,
          effectiveFrom2003To2008:item.effectiveFrom2003To2008,
          effectiveFrom2008To2018:item.effectiveFrom2008To2018,
          effectiveFrom2018To2023:item.effectiveFrom2018To2023,
          investmentAmountFrom2003To2008: this.parseNumberInput(item.investmentAmountFrom2003To2008),
          investmentAmountFrom2008To2018: this.parseNumberInput(item.investmentAmountFrom2008To2018),
          investmentAmountFrom2018To2023: this.parseNumberInput(item.investmentAmountFrom2018To2023),
          investmentAmountTotalFrom2003To2008: this.parseNumberInput(item.investmentAmountTotalFrom2003To2008),
          investmentAmountTotalFrom2008To2018: this.parseNumberInput(item.investmentAmountTotalFrom2008To2018),
          investmentAmountTotalFrom2018To2023: this.parseNumberInput(item.investmentAmountTotalFrom2018To2023),
          instructions:item.instructions
        };

        this.stuInvestmentMsgList.push(newItem)

      });
    },

    formatStuInvestmentMsgToString(){

      this.stuInvestmentMsgDisplayList.splice(0, this.stuInvestmentMsgDisplayList.length);
      this.stuInvestmentMsgList.forEach(item => {
        const newItem = {
          stuNo: item.stuNo,
          kindId: item.kindId,
          kindName: item.kindName,
          effectiveFrom2003To2008:item.effectiveFrom2003To2008,
          effectiveFrom2008To2018:item.effectiveFrom2008To2018,
          effectiveFrom2018To2023:item.effectiveFrom2018To2023,
          investmentAmountFrom2003To2008: this.formatNumberInput(item.investmentAmountFrom2003To2008),
          investmentAmountFrom2008To2018: this.formatNumberInput(item.investmentAmountFrom2008To2018),
          investmentAmountFrom2018To2023: this.formatNumberInput(item.investmentAmountFrom2018To2023),
          investmentAmountTotalFrom2003To2008: this.formatNumberInput(item.investmentAmountTotalFrom2003To2008),
          investmentAmountTotalFrom2008To2018: this.formatNumberInput(item.investmentAmountTotalFrom2008To2018),
          investmentAmountTotalFrom2018To2023: this.formatNumberInput(item.investmentAmountTotalFrom2018To2023),
          instructions:item.instructions

        };
        this.stuInvestmentMsgDisplayList.push(newItem)

      });

    },

    tableRowClassEnd({rowIndex}) {
      if (rowIndex === 0) {
        return "background:rgb(140, 197, 255);"
      }
      return '';
    },

    rowStyle() {
      return "text-align:center";
    },

  },
  data() {
    return {

      seminar: {
        seminarId: '',
        investAmount: 0,
        expPeriodFlag: 0
      },

      stuAssetsMsgList: [{
        stuId: '',
        stuNo: '',
        seminarId: '',
        intendedRate: '',
        investFrom2003To2008Start: 0,
        fndFrom2003To2008Start: 0,
        cashFrom2003To2008Start: 0,
        investFrom2003To2008End: 0,
        fndFrom2003To2008End: 0,
        cashFrom2003To2008End: 0,
        investFrom2008To2018Start: 100000,
        fndFrom2008To2018Start: 0,
        cashFrom2008To2018Start: 100000,
        investFrom2008To2018End: 0,
        fndFrom2008To2018End: 0,
        cashFrom2008To2018End: 0,
        investFrom2018To2023Start: 0,
        fndFrom2018To2023Start: 0,
        cashFrom2018To2023Start: 0,
        investFrom2018To2023End: 0,
        fndFrom2018To2023End: 0,
        cashFrom2018To2023End: 0,
        statusFlgFrom2003To2008: 0,
        statusFlgFrom2008To2018: 0,
        statusFlgFrom2018To2023: 0,
        resultInvest: 0,
        resultCash: 0,
        yieldFrom2003To2008: 0,
        yieldFrom2008To2018:0,
        yieldFrom2018To2023:0
      }],

      stuInvestmentMsgList: [{
        stuNo: '',
        kindId: 0,
        kindName: '',
        effectiveFrom2003To2008:false,
        effectiveFrom2008To2018:false,
        effectiveFrom2018To2023:false,
        investmentAmountFrom2003To2008: 0,
        investmentAmountTotalFrom2003To2008: 0,
        investmentAmountFrom2008To2018: 0,
        investmentAmountTotalFrom2008To2018: 0,
        investmentAmountFrom2018To2023: 0,
        investmentAmountTotalFrom2018To2023: 0,
        instructions: ''
      }],

      stuInvestmentMsgDisplayList: [
        {
          stuNo: '',
          kindId: 0,
          kindName: '',
          effectiveFrom2003To2008:false,
          effectiveFrom2008To2018:false,
          effectiveFrom2018To2023:false,
          investmentAmountFrom2003To2008: '',
          investmentAmountTotalFrom2003To2008: '',
          investmentAmountFrom2008To2018: '',
          investmentAmountTotalFrom2008To2018: '',
          investmentAmountFrom2018To2023: '',
          investmentAmountTotalFrom2018To2023: '',
          instructions: ''
        },

      ],
      resultInvestMoney:'',
      options: [{
        value: 'changeOne',
        label: '安定派：年利回り3%以下'
      }, {
        value: 'changeTwo',
        label: '中間派：年利回り3~8%'
      }, {
        value: 'changeThird',
        label: '積極派：年利回り8％以上'
      }
      ],

      select:''
    }

  }
}
</script>